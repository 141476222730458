// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","statisticsCard":"statisticsCard_statisticsCard__-clR3","title":"statisticsCard_title__sig4S","titleContent":"statisticsCard_titleContent__uk-4C","count":"statisticsCard_count__GnUq-"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"statisticsCard\":\"statisticsCard_statisticsCard__-clR3\",\"title\":\"statisticsCard_title__sig4S\",\"titleContent\":\"statisticsCard_titleContent__uk-4C\",\"count\":\"statisticsCard_count__GnUq-\"}";
        // 1743672216930
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  