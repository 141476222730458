// extracted by mini-css-extract-plugin
export default {"resizeHandle":"resizableWrapper_resizeHandle__tNThG","verticalHandle":"resizableWrapper_verticalHandle__H2Z9U","leftHandle":"resizableWrapper_leftHandle__t8Zaz","rightHandle":"resizableWrapper_rightHandle__-JJZY","horizontalHandle":"resizableWrapper_horizontalHandle__vFpW7","bottomHandle":"resizableWrapper_bottomHandle__5hrh4","topHandle":"resizableWrapper_topHandle__uzQE+"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"resizeHandle\":\"resizableWrapper_resizeHandle__tNThG\",\"verticalHandle\":\"resizableWrapper_verticalHandle__H2Z9U\",\"leftHandle\":\"resizableWrapper_leftHandle__t8Zaz\",\"rightHandle\":\"resizableWrapper_rightHandle__-JJZY\",\"horizontalHandle\":\"resizableWrapper_horizontalHandle__vFpW7\",\"bottomHandle\":\"resizableWrapper_bottomHandle__5hrh4\",\"topHandle\":\"resizableWrapper_topHandle__uzQE+\"}";
        // 1743672219613
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  