import React, { useEffect, useRef, useState } from "react";
import qs from "qs";
import {
  fatigueInfo,
  ftpResourceRequestSingle,
} from "@/service/jtDevicePlayback";
import { streamTransfer } from "../jtDevicePlayback/components/ftpResourceTable";
import style from "./index.module.scss";
import { importAliMap, initMap } from "@/utils/map";
import { renderGps, renderMarker } from "@/utils/driveAnalysis";
import gcoord from "gcoord";
import { Descriptions, message } from "antd";
import response from "./response.json";

function isImageUrl(url: string) {
  // 定义一个正则表达式来匹配常见的图片格式
  const imageFormatRegex = /\.(jpg|jpeg|png|gif|bmp|webp)/i;

  // 使用正则表达式测试URL
  return imageFormatRegex.test(url);
}

export const LSHLAlarmReport = () => {
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const params = qs.parse(window.location.search.split("?")[1]);
  const [imageUrl, setImageUrl] = useState("");
  const gpsData: any = []; // gps数据(AEB设备)
  const alarmsData: any = []; // 报警数据(AEB设备)
  const mapRef: any = useRef(null); // 存储地图实例
  const alarmLabelsRef: any = useRef({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  });
  const infoCallback = (res: any) => {
    const imageFlag = isImageUrl(res?.data?.url || "");
    if (imageFlag) {
      setImageUrl(res?.data?.url);
    } else {
      const videoElement = document.getElementById(
        "playbackVideo"
      ) as HTMLMediaElement;
      videoElement.src = (res?.data?.url as any as string) || "";
      videoElement?.play();
    }

    let alarmList: any[] = [];
    res.data.alarmList.forEach((alarm: any) => {
      alarmList.push({
        ...{ ...alarm },
        ...{ lat: alarm.lng, lon: alarm.lat },
      });
    });
    console.log(alarmList, "1231313");
    importAliMap(() => initMapCallback(res.data.gpsList, alarmList));
  };
  // setTimeout(() => {
  //   infoCallback(response)
  // }, 100)

  fatigueInfo({ ...params }).then((res) => {
    const data = res.data;
    infoCallback(data);
  })

  // 地图初始化成功的回调
  const initMapCallback = async (gpsData: any, alarmsData: any) => {
    try {
      initMap(mapDomRef, mapRef, "top");
      mapRef.current?.clearMap();
      gpsData.length && renderGps(gpsData, mapRef, false, true, true);

      alarmsData.length &&
        renderMarker(alarmsData, 0, mapRef, alarmLabelsRef, true);
      // 确定地图中心点(根据选择的事件类型不同动态确定地图的中心点)
      const centerPositionData = gpsData[0] || alarmsData[0] || {};
      const { lat, lon } = centerPositionData;
      if (lat && lon) {
        const transformCenterPosition = gcoord.transform(
          [lat, lon],
          gcoord.WGS84,
          gcoord.AMap
        );
        mapRef.current.setCenter(
          new window.AMap.LngLat(
            transformCenterPosition[0],
            transformCenterPosition[1]
          )
        );
      }
      mapRef.current?.setFitView();
    } catch (error) {
      console.log(error);
      message.error("地图初始化失败");
    }
  };

  return (
    <>
      <div>
        <Descriptions title="报警信息" layout="vertical" column={1}>
          <Descriptions.Item label="车牌号">
            {params.plateNumber as string}
          </Descriptions.Item>
          <Descriptions.Item label="报警影像">
            {imageUrl && (
              <div>
                <img
                  style={{ height: "calc(100vh - 20px)", width: "100vw" }}
                  src={imageUrl}
                ></img>
              </div>
            )}
            {!imageUrl && (
              <div style={{ height: "calc(100vh - 20px)", width: "100vw" }}>
                <video
                  id="playbackVideo"
                  src=""
                  width="100%"
                  height="100%"
                  controls
                ></video>
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="报警地图">
            {''}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div ref={mapDomRef} className={style.map} />
    </>
  );
};
