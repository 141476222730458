/**
 * 固件管理
 */
import React, { useState } from 'react';
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Modal,
  message
} from 'antd';
import dayjs from 'dayjs';
import {
  firmwareListRequest,
  firmwareDeleteRequest
} from '@/service/firmwareManage';
import FirmwareBindListModal from './components/firmwareBindListModal';
import AddModal from './components/addModal';
import BindModal from './components/bindModal';
import FirmwareInfoModal from './components/firmwareInfoModal';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { useTable } from '@/utils/useTable';
import { firmwareType, firmwareGroup } from '@/constants/firmwareManage';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';
import { useSelector } from 'react-redux';
const { confirm } = Modal;

const mobileList = '18902038197,13811686172,13426403996,17802273390,18903268746,16622372780,15660955029,15165492949,18719707651,15336956526,19801322487,13730462566,15810088882,15122009229'
const prodMobileList = '15336956526,15122009229'

const FirmwareManage = () => {
  const [selectKeys, setSelectKeys] = useState([]); // 固件列表选择的值
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateData, setOperateData] = useState<any>({}); // 操作时的数据(添加或者绑定)
  const { userDetail } = useSelector((state: any) => state.userStore);

  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: firmwareListRequest,
      initPageSize: 40,
      searchParams
    });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.version = (values.version || '').trim();
    if (!newSearchParams.version) {
      delete newSearchParams.version;
    }
    setSearchParams(newSearchParams);
  };

  // 删除固件
  const deleteFirmware = async () => {
    const res = await firmwareDeleteRequest({ ids: selectKeys });
    if (res.data?.code === 0 && res.data?.data) {
      message.success('删除成功');
      getData();
      setSelectKeys([]);
    } else {
      message.error('删除失败');
    }
  };

  // 删除选择固件的弹窗
  const deleteFirmwareConfirm = () => {
    confirm({
      title: '确定删除吗？',
      onOk: deleteFirmware,
      centered: true
    });
  };

  // 渲染固件类型列
  const renderTypeColumn = (value: string) => {
    const matchType = firmwareType.find((item: any) => item.value === value);
    return matchType?.label || value || '未知类型';
  };

  // 渲染固件分组列
  const renderGroupColumn = (value: number) => {
    const matchGroup = firmwareGroup.find((item: any) => item.value === value);
    return matchGroup?.label || value;
  };

  // 渲染时间列
  const renderTimeColumn = (value: string) => {
    if (value) {
      return (
        <RenderEllipsisColumn
          text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
        />
      );
    } else {
      return null;
    }
  };

  // 渲染固件分组单元格样式
  const renderGroupColumnCell = (record: any) => {
    if (record.packet === 0) {
      return {
        className: 'warningTableCell'
      };
    }
    return {};
  };

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70
    },
    {
      title: '固件类型',
      dataIndex: 'type',
      width: 110,
      render: (value: string) => renderTypeColumn(value)
    },
    {
      title: '固件分组',
      dataIndex: 'packet',
      width: 100,
      render: (value: number) => renderGroupColumn(value),
      onCell: (record: any) => renderGroupColumnCell(record)
    },
    {
      title: '版本号',
      dataIndex: 'version',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '版本描述',
      dataIndex: 'ps',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => renderTimeColumn(value)
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => renderTimeColumn(value)
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 260,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {mobileList.indexOf(userDetail.mobile) !== -1 && item.packet == 0 &&  <Button
              type="link"
              onClick={() => setOperateData({ type: 'bind', data: item.id })}
            >
              绑定车辆
            </Button>}
            {prodMobileList.indexOf(userDetail.mobile) !== -1 && item.packet == 1 &&  <Button
              type="link"
              onClick={() => setOperateData({ type: 'bind', data: item.id })}
            >
              绑定车辆
            </Button>}
             {/* <PermissionButton
              type="link"
              onClick={() => setOperateData({ type: 'bind', data: item.id })}
              operateType="/car-admin/firmware-manage/bind"
            >
              绑定车辆
            </PermissionButton> */}
            <Button
              type="link"
              onClick={() =>
                setOperateData({ type: 'bindList', data: item.id })
              }
            >
              查看已绑定设备
            </Button>
            <Button
              type="link"
              onClick={() => setOperateData({ type: 'info', data: item })}
            >
              固件信息
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <Card className={styles.fixPageContentBox} bordered={false}>
      <Form onFinish={onSearch} autoComplete="off">
        <Row gutter={formSearchGutter}>
          <Col span={6}>
            <Form.Item label="版本" name="version">
              <Input placeholder="请输入" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="固件类型" name="type">
              <Select
                placeholder="请选择固件类型"
                options={firmwareType}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="固件分组" name="packet">
              <Select
                placeholder="请选择固件分组"
                options={[{ label: '全部', value: '' }, ...firmwareGroup]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/firmware-manage/delete"
          type="primary"
          onClick={deleteFirmwareConfirm}
          disabled={selectKeys.length === 0}
        >
          删除
        </PermissionButton>
        <PermissionButton
          operateType="/car-admin/firmware-manage/add"
          type="primary"
          onClick={() => setOperateData({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        rowSelection={{
          onChange: (keys: any) => setSelectKeys(keys),
          fixed: true
        }}
      />
      {operateData?.type === 'bindList' && (
        <FirmwareBindListModal
          closeModal={() => setOperateData({})}
          id={operateData.data}
        />
      )}
      {operateData?.type === 'add' && (
        <AddModal
          closeModal={() => setOperateData({})}
          getFirmwareData={getData}
        />
      )}
      {operateData?.type === 'bind' && (
        <BindModal
          closeModal={() => setOperateData({})}
          getFirmwareData={getData}
          id={operateData?.data}
        />
      )}
      {operateData?.type === 'info' && (
        <FirmwareInfoModal
          closeModal={() => setOperateData({})}
          data={operateData?.data || {}}
        />
      )}
    </Card>
  );
};

export default FirmwareManage;
