import axios from "axios";
import { message } from "antd";
import localStorageUtil from "@/utils/localStorageUtil";
import { getFile } from "@/service/home";

// 通过a标签和url下载文件
export const downloadUrl = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};

// 下载Blob类型内容
export const downloadBlob = (blob: Blob, fileName: string) => {
  const blobUrl = window.URL.createObjectURL(blob);
  downloadUrl(blobUrl, fileName);
  URL.revokeObjectURL(blobUrl);
};

export const downloadTempele = (url: string) => {
  getFile({key: url}).then((res) => {
    downloadUrl(res.data.data, url)
  })
}

// 下载文件
export const downloadFile = async (
  url: string,
  fileName: string,
  hasToken?: boolean
) => {
  let headers = {};
  if (!url.startsWith("http") || hasToken) {
    headers = { access_token: localStorageUtil.getItem("access_token") };
  }
  return axios
    .get(url, { responseType: "blob", headers })
    .then((res) => {
      const blob = new Blob([res.data]);
      downloadBlob(blob, fileName);
      message.success("下载成功");
      return res;
    })
    .catch((error) => {
      message.error("下载失败");
      return Promise.reject(error);
    });
};

// 下载文件
export const fetchDownloadFile = async (
  url: string,
  fileName: string,
  hasToken?: boolean
) => {
  return fetch(url)
    .then((res) => {
      res.blob().then((blob) => {
        downloadBlob(blob, fileName);
        message.success("下载成功");
      });
    })
    .catch((error) => {
      message.error("下载失败");
      return Promise.reject(error);
    });
};

// base64 to blob
export const dataURLtoBlob = (dataurl: string) => {
  try {
    let arr: any = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  } catch {
    let arr = dataurl.split(",");
    let bstr = atob(arr[0]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr]);
  }
};
