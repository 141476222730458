// extracted by mini-css-extract-plugin
export default {"uploadImageVideo":"uploadFile_uploadImageVideo__wDR2i","uploadCard":"uploadFile_uploadCard__UaszM","previewBox":"uploadFile_previewBox__svR5-","previewItem":"uploadFile_previewItem__7Fr+N","deleteIcon":"uploadFile_deleteIcon__1ujtc"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"uploadImageVideo\":\"uploadFile_uploadImageVideo__wDR2i\",\"uploadCard\":\"uploadFile_uploadCard__UaszM\",\"previewBox\":\"uploadFile_previewBox__svR5-\",\"previewItem\":\"uploadFile_previewItem__7Fr+N\",\"deleteIcon\":\"uploadFile_deleteIcon__1ujtc\"}";
        // 1743672220044
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  