/**
 * 操作承保记录(添加或编辑)
 */
import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
  Select,
  Upload,
  Image,
  Empty,
  Checkbox,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import UploadFile from "@/components/uploadFile";
import {
  getCityListRequest,
  getProvincialListRequest,
  upsertUnderwritingRequest,
} from "@/service/underwritingRecord";
import QuickDatePicker from "@/components/quickDatePicker";
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";
import { carTypeListRequest } from "@/service/offerRecord";
import { installmentTypeData } from "@/constants/underwritingRecord";
import { insureListRequest } from "@/service/insurerManage";
import localStorageUtil from "@/utils/localStorageUtil";
import PermissionButton from "@/components/permissionButton";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { companyListRequest } from "@/service/companyManage";
import { channelListRequest } from "@/service/channelRecode";
import { LabelLayout } from "echarts/features";
import {
  endType,
  gradeList,
  payType,
} from "@/pages/offerRecord/components/underWritingModal";

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}

export const deviceType = [
  {
    name: "AEBS",
    value: "AEBS",
  },
  {
    name: "ADAS",
    value: "ADAS",
  },
];

export const carOpenList =
  "江苏涵伟物流有限公司、江苏淮轩物流有限公司、河北顺安环保工程有限公司、河北麒麟岩土工程有限公司、平泉秋成运输有限公司、平泉秋成商贸有限公司、平泉尚源运输有限公司、北京尚源商贸有限公司、承德焱顺商贸有限公司、承德沭铭商贸有限公司、平泉市阳何商贸有限公司、平泉市双利运输有限公司、河北麒麟岩土工程有限公司沧州高新区分公司";

const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
  const [uploadPaySuccessList, setUploadPaySuccessList] = useState<any>([]); // 上传文件
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  const [areaCode, setAreaCode] = useState<any>([]); // 省
  const [cityCode, setCityCode] = useState<any>([]); // 市
  const [vehicleType, setVehicleType] = useState<any>([]); // 车辆类型
  const [commercialCompany, setCommercialCompany] = useState<any>([]); // 承保保司
  const [mode, setMode] = useState<any>([]); // 承保模式
  const [closeFeeState, setCloseFeeState] = useState<any>([]); // 结算状态
  const [uploadLoading, setUploadLoading] = useState<boolean>(false); // 行驶证是否上传中
  const [companyList, setCompanyList] = useState<any>([]); // 车队列表
  const [channelOptions, setChannelOptions] = useState<any>([]); // 渠道列表
  const [ziXieCar, setZiXieCar] = useState<any>("");

  // 编辑的时候赋值
  useEffect(() => {
    getFuelType();
    getCity();
    getCommercialCompany();
    getCloseFeeState(); //结算状态
    getMode();
    getCompanyList().then((companyList) => setCompanyList(companyList));
    getAllChannelOptions().then((channelOptions) =>
      setChannelOptions(channelOptions)
    );
    if (type === "edit") {
      const params = { ...data };
      if (
        params.commercialBeginDate !== null ||
        params.commercialEndDate !== null
      ) {
        params.commercialBeginDate = dayjs(data.commercialBeginDate);
        params.commercialEndDate = dayjs(data.commercialEndDate);
      }
      if (
        params.compulsoryBeginDate !== null ||
        params.compulsoryEndDate !== null
      ) {
        params.compulsoryBeginDate = dayjs(data.compulsoryBeginDate);
        params.compulsoryEndDate = dayjs(data.compulsoryEndDate);
      }
      if (params.payDay !== null) {
        params.payDay = dayjs(data.payDay);
      }
      if (params.registerDate) {
        params.registerDate = dayjs(data.registerDate);
      }
      if (params.signDay !== null) {
        params.signDay = dayjs(data.signDay);
      }
      if (params.threeElectricityFlag) {
        params.threeElectricityFlag = ["是"];
      } else {
        params.threeElectricityFlag = [];
      }

      if (params.selfBurningFlag) {
        params.selfBurningFlag = ["是"];
      } else {
        params.selfBurningFlag = [];
      }
      if (params.liftFlag) {
        params.liftFlag = ["是"];
      } else {
        params.liftFlag = [];
      }
      form.setFieldsValue(params);
    }
    if (type === "copy") {
      const params = { ...data };
      if (
        params.commercialBeginDate !== null ||
        params.commercialEndDate !== null
      ) {
        params.commercialBeginDate = dayjs(data.commercialBeginDate);
        params.commercialEndDate = dayjs(data.commercialEndDate);
      }
      if (
        params.compulsoryBeginDate !== null ||
        params.compulsoryEndDate !== null
      ) {
        params.compulsoryBeginDate = dayjs(data.compulsoryBeginDate);
        params.compulsoryEndDate = dayjs(data.compulsoryEndDate);
      }
      if (params.payDay !== null) {
        params.payDay = dayjs(data.payDay);
      }

      if (params.registerDate) {
        params.registerDate = dayjs(data.registerDate);
      }
      if (params.signDay !== null) {
        params.signDay = dayjs(data.signDay);
      }
      if (params.threeElectricityFlag) {
        params.threeElectricityFlag = ["是"];
      } else {
        params.threeElectricityFlag = [];
      }

      if (params.selfBurningFlag) {
        params.selfBurningFlag = ["是"];
      } else {
        params.selfBurningFlag = [];
      }
      if (params.liftFlag) {
        params.liftFlag = ["是"];
      } else {
        params.liftFlag = [];
      }
      delete params.id;
      form.setFieldsValue(params);
    }
    if (type == "add") {
      if (data !== "") {
        form.setFieldsValue(data);
      }
    }
  }, []);

  // 获取城市 以及 车辆类型
  const getCity = async () => {
    const provincialList = await getProvincialListRequest();
    const cityList = await getCityListRequest();
    const carType = await carTypeListRequest();
    const aa: any = [];
    const bb: any = [];
    const cc: any = [];
    provincialList.data?.data.map((item: any) => {
      aa.push({ label: item.name, value: item.name });
    });
    cityList.data?.data.map((item: any) => {
      bb.push({ label: item.name, value: item.name });
    });
    carType.data?.data.map((item: any) => {
      if (item.parentCode !== null) {
        cc.push({ label: item.name, value: item.name });
      }
    });
    setAreaCode(aa);
    setCityCode(bb);
    setVehicleType(cc);
  };

  // 获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.fuelType],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setFuelType(tags);
  };

  // 获取承保保司
  const getCommercialCompany = async () => {
    const res = await insureListRequest("");
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name } = item;
      tags.push({ label: name, value: name });
    });
    setCommercialCompany(tags);
  };

  const vehicleTypeChange = (e: any) => {
    e?.indexOf("自卸") == -1 ? setZiXieCar("") : setZiXieCar("zixie");
    if (e?.indexOf("自卸") == -1) {
      form.setFieldValue("liftFlag", []);
    }
  };

  //获取渠道
  const getAllChannelOptions = async () => {
    const res = await channelListRequest({ page: 1, limit: 50000, name: "" });
    const channelOptions = (res?.data?.data || []).map(
      (item: { id: number; name: string }) => {
        return {
          label: item.name,
          value: item.name,
          key: item.id,
        };
      }
    );
    return channelOptions || [];
  };

  // 获取结算状态
  const getCloseFeeState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.closeFeeState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCloseFeeState(tags);
  };

  // 获取承保模式
  const getMode = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.mode],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setMode(tags);
  };

  //获取车队列表
  const getCompanyList = async () => {
    const res = await companyListRequest({ page: 1, limit: 20000, name: "" });
    const data = res.data?.data || [];
    const companyList = data.map((item: any) => {
      return {
        label: item.name,
        value: item.name,
        key: item.id,
      };
    });
    return companyList || [];
  };

  // 上传行驶证
  const onUploadChange = async (info: any) => {
    if (info.file.status === "uploading") {
      setUploadLoading(true);
    }
    if (info.file.status === "error") {
      setUploadLoading(false);
      message.error("上传失败");
    }
    if (info.file.status === "done") {
      // 上传成功之后
      setUploadLoading(false);
      message.success("上传成功");
    }
  };

  // 上传前限制图片大小
  const beforeUpload = (file: any) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error("请上传20M以内图片");
    }
    const isLtType = file.type.startsWith("image");
    if (!isLtType) {
      message.error("请上传图片");
    }
    return isLt20M && isLtType;
  };

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false);
      try {
        const params = { ...values };
        if (values.commercialBeginDate != null) {
          params.commercialBeginDate = dayjs(values.commercialBeginDate).format(
            "YYYY-MM-DD"
          );
        }
        if (values.commercialEndDate != null) {
          params.commercialEndDate = dayjs(values.commercialEndDate).format(
            "YYYY-MM-DD"
          );
        }
        if (values.compulsoryBeginDate != null) {
          params.compulsoryBeginDate = dayjs(values.compulsoryBeginDate).format(
            "YYYY-MM-DD"
          );
        }
        if (values.compulsoryEndDate != null) {
          params.compulsoryEndDate = dayjs(values.compulsoryEndDate).format(
            "YYYY-MM-DD"
          );
        }
        if (values.payDay != null) {
          params.payDay = dayjs(values.payDay).format("YYYY-MM-DD");
        }
        if (values.signDay != null) {
          params.signDay = dayjs(values.signDay).format("YYYY-MM-DD");
        }
        if (values.registerDate != null) {
          params.registerDate = dayjs(values.registerDate).format("YYYY-MM-DD");
        }

        params.plateNumber = (values.plateNumber || "").trim();

        if (params.threeElectricityFlag?.length) {
          params.threeElectricityFlag = true;
        } else {
          params.threeElectricityFlag = false;
        }

        if (params.selfBurningFlag?.length) {
          params.selfBurningFlag = true;
        } else {
          params.selfBurningFlag = false;
        }

        if (params.liftFlag?.length) {
          params.liftFlag = true;
        } else {
          params.liftFlag = false;
        }

        console.log(params);
        if (type === "edit") {
          params.id = data.id;
          //如果 params.drivingLicense  是对象则params.drivingLicense = params.drivingLicense.file.response.data.key
          //如果 params.drivingLicense  是字符串则params.drivingLicense = params.drivingLicense
          if (params.drivingLicense !== null) {
            if (typeof params.drivingLicense === "object") {
              // params.drivingLicense 是一个对象
              params.drivingLicense =
                params.drivingLicense.file.response.data.key;
            } else {
              // params.drivingLicense 不是一个对象
              params.drivingLicense = params.drivingLicense;
            }
          } else {
            params.drivingLicense = null;
          }
        }
        if (type === "add") {
          params.drivingLicense = params.drivingLicense?.file.response.data.key;
        }
        if (uploadSuccessList.length) {
          params.attachments = uploadSuccessList;
        } else {
          params.attachments = null;
        }
        if (uploadPaySuccessList.length) {
          params.resources = uploadPaySuccessList;
        } else {
          params.resources = null;
        }

        const res = await upsertUnderwritingRequest(params);

        if (res.data?.code === 0) {
          message.success(
            type === "add" || type === "copy" ? "添加成功" : "修改成功"
          );
          closeModal();
          reloadData();
        }
      } catch (error) {
        message.error(error as string);
      } finally {
        setSubmitNoRepetition(true);
      }
    }
  };

  const changeInstallmentType = (e: any) => {
    const values = form.getFieldsValue();
    if (values.parnter === "付同辉") {
      return;
    }
    if (values.channelManager === "陈科声") {
      return;
    }
    if (
      carOpenList.split("、").find((item) => {
        return item === values.applicant;
      })
    ) {
      return;
    }
    if (e === "分期") {
      if (!values.threeElectricityFlag?.length) {
        form.setFieldValue("settlementType", "常规-分期结算");
      } else {
        form.setFieldValue("settlementType", "新能源含三电-分期结算");
      }
    }
    if (e === "不分期") {
      if (!values.threeElectricityFlag?.length) {
        form.setFieldValue("settlementType", "常规-整笔结算");
      } else {
        form.setFieldValue("settlementType", "新能源含三电-整笔结算");
      }
    }
  };

  const changeSettlement = (e: any, type: string) => {
    console.log(e, type);
    if (type === "partner") {
      if (e?.target?.value === "付同辉") {
        form.setFieldValue("settlementType", "付同辉专项结算");
      }
    }
    if (type === "channelManager") {
      if (e?.target?.value === "陈科声") {
        form.setFieldValue("settlementType", "陈科声专项结算");
      }
    }
    if (type === "applicant") {
      console.log(
        carOpenList.split("、").find((item) => {
          return item === e?.target?.value;
        }),
        carOpenList.split("、"),
        e.target.value
      );
      if (
        carOpenList.split("、").find((item) => {
          return item === e?.target?.value;
        })
      ) {
        if (form.getFieldValue("threeElectricityFlag")?.length) {
          form.setFieldValue("settlementType", "新能源含三电-一次性返佣结算");
        } else {
          form.setFieldValue("settlementType", "常规-一次性返佣结算");
        }
      }
    }

    if (type === "threeElectricityFlag") {
      const values = form.getFieldsValue();
      if (values.parnter === "付同辉") {
        return;
      }
      if (values.channelManager === "陈科声") {
        return;
      }
      if (
        carOpenList.split("、").find((item) => {
          return item === values.applicant;
        })
      ) {
        if (e?.length) {
          form.setFieldValue("settlementType", "新能源含三电-一次性返佣结算");
        } else {
          form.setFieldValue("settlementType", "常规-一次性返佣结算");
        }
        return;
      }
      if (e?.length) {
        if (values.installmentType === "分期") {
          form.setFieldValue("settlementType", "新能源含三电-分期结算");
        }
        if (values.installmentType === "不分期") {
          form.setFieldValue("settlementType", "新能源含三电-整笔结算");
        }
      }

      if (!e?.length) {
        if (values.installmentType === "分期") {
          form.setFieldValue("settlementType", "常规-分期结算");
        }
        if (values.installmentType === "不分期") {
          form.setFieldValue("settlementType", "常规-整笔结算");
        }
      }
    }
  };

  return (
    <Modal
      title={type === "add" ? "添加承保记录" : "编辑承保记录"}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
      centered
      maskClosable={false}
      width={1000}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={submit}
        scrollToFirstError
        initialValues={{
          mode: "中富模式",
          commercialCompany: "中富祥云（北京）科技有限公司",
        }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="承保模式" name="mode">
              <Select
                placeholder="请选择承保模式"
                options={mode}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="合同号" name="contractSn">
              <Input placeholder="请输入合同号" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="承保保司" name="commercialCompany">
              <Select
                placeholder="请选择承保保司"
                options={commercialCompany}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="请输入车牌号"
                allowClear
                onChange={(e) => {
                  const newV = e.target.value.replace(/\s+/g, "");
                  form.setFieldValue("plateNumber", newV);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="车架号" name="frameNumber" required>
              <Input
                placeholder="请输入车架号"
                allowClear
                onChange={(e) => {
                  const newV = e.target.value.replace(/\s+/g, "");
                  form.setFieldValue("frameNumber", newV);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="车辆类型"
              name="carType"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="请选择车辆类型"
                options={vehicleType}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
                onChange={(e) => {
                  vehicleTypeChange(e);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="燃料类型"
              name="fuelType"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="请选择燃料类型"
                options={fuelType}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="省份" name="area">
              <Select
                placeholder="请选择省份"
                options={areaCode}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              name={"threeElectricityFlag"}
              label={"含三电"}
            >
              <Checkbox.Group
                onChange={(e) => {
                  changeSettlement(e, "threeElectricityFlag");
                }}
                defaultValue={[]}
                options={[{ label: "", value: "是" }]}
              ></Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              name={"selfBurningFlag"}
              label={
                <>
                  {" "}
                  <Tooltip
                    placement="top"
                    title={"特殊结构慎选自燃选项，危险品运输车辆拒绝自燃选项"}
                  >
                    <InfoCircleOutlined />
                    <span>含自燃</span>
                  </Tooltip>
                </>
              }
            >
              <Checkbox.Group
                defaultValue={[]}
                options={[
                  {
                    label: "",
                    value: "是",
                  },
                ]}
              ></Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              name={"liftFlag"}
              label={
                <>
                  <>
                    {" "}
                    <Tooltip placement="top" title={"非自卸车不能勾选举升"}>
                      <InfoCircleOutlined />
                      <span>含举升</span>
                    </Tooltip>
                  </>
                </>
              }
            >
              <Checkbox.Group
                defaultValue={[]}
                options={[
                  {
                    label: "",
                    value: "是",
                    disabled: ziXieCar != "zixie",
                  },
                ]}
              ></Checkbox.Group>
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item label="城市" name="city">
              <Select
                placeholder="请选择城市"
                options={cityCode}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"

              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="市场归属" name="department">
              {/*<Input placeholder="请输入市场归属" allowClear/>*/}
              <Select
                options={channelOptions}
                placeholder="请选择市场归属"
                showSearch
                allowClear={true}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="渠道经理" name="channelManager">
              <Input
                placeholder="请输入渠道经理"
                allowClear
                onChange={(e) => {
                  changeSettlement(e, "channelManager");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="合伙人" name="partner">
              <Input
                placeholder="请输入合伙人"
                allowClear
                onChange={(e) => {
                  changeSettlement(e, "partner");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="出单人" name="issuer">
              <Input placeholder="请输入出单人" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="车辆所有人" name="carOwner">
              {/*<Input placeholder="请输入车辆所有人"  allowClear/>*/}
              <Select
                options={companyList}
                placeholder="请选择车辆所有人"
                showSearch
                filterOption={(input, option) => {
                  const label = (option?.label || "") as string;
                  return label.indexOf(input.trim()) >= 0;
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="投保人"
              name="applicant"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="请输入投保人"
                allowClear
                onChange={(e) => {
                  changeSettlement(e, "applicant");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="优选金额"
              name="rewardFee"
              rules={[{ required: true }]}
            >
              <Input placeholder="" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="结算状态" name="settlementState">
              <Select
                placeholder="请选择结算状态"
                options={closeFeeState}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate noStyle>
          {() => {
            // 中富模式
            if (form.getFieldValue("mode") == "中富模式") {
              return (
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="服务单号" name="commercialNumber">
                      <Input placeholder="请输入服务单号" allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="起保日期" name="commercialBeginDate">
                      <QuickDatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="到期日期" name="commercialEndDate">
                      <QuickDatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="保费总金额"
                      name="commercialFee"
                      rules={[{ required: true, message: "请输入保费总金额" }]}
                    >
                      <Input placeholder="请输入保费总金额" allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else {
              // 保险模式
              return (
                <>
                  <Row gutter={24}>
                    <Col span={6}>
                      <Form.Item label="商业险保单号" name="commercialNumber">
                        <Input placeholder="请输入商业险保单号" allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="商业险起保日期"
                        name="commercialBeginDate"
                      >
                        <QuickDatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="商业险到期日期"
                        name="commercialEndDate"
                      >
                        <QuickDatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="商业总保费"
                        name="commercialFee"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="请输入商业总保费" allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={4}>
                      <Form.Item label="交强险保单号" name="compulsoryNumber">
                        <Input placeholder="请输入交强险保单号" allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="交强险起保日期"
                        name="compulsoryBeginDate"
                      >
                        <QuickDatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="交强险到期日期"
                        name="compulsoryEndDate"
                      >
                        <QuickDatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item label="交强总保费" name="compulsoryFee">
                        <Input placeholder="请输入交强总保费" allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item label="交强承保保司" name="compulsoryCompany">
                        <Select
                          placeholder="请选择承保保司"
                          options={commercialCompany}
                          allowClear
                          showArrow
                          showSearch
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }
          }}
        </Form.Item>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="原保司" name="mainBodyChange">
              <Select
                placeholder="请选择原保司"
                options={commercialCompany}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="分期类型"
              name="installmentType"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="请选择分期类型"
                options={installmentTypeData}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
                onChange={(e) => {
                  changeInstallmentType(e);
                }}
              />
            </Form.Item>
          </Col>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.installmentType !== curValues.installmentType
            }
            noStyle
          >
            {({ getFieldValue, setFieldsValue }) => {
              const installmentType = getFieldValue("installmentType");
              if (installmentType === "分期") {
                if (type === "add") {
                  setFieldsValue({ installmentCount: 10 });
                }
              } else {
                if (type === "add") {
                  setFieldsValue({ installmentCount: 0 });
                }
              }
              return null;
            }}
          </Form.Item>
          <Col span={6}>
            <Form.Item
              label="分期数"
              name="installmentCount"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入分期数" allowClear />
            </Form.Item>
          </Col>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.installmentType !== curValues.installmentType ||
                prevValues.installmentCount !== curValues.installmentCount ||
                prevValues.commercialFee !== curValues.commercialFee
              );
            }}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const installmentCount = getFieldValue("installmentCount");
              if (installmentCount === 0) {
                setFieldsValue({ downPayment: 0 });
                setFieldsValue({ monthlyPayment: 0 });
              } else {
                let totalPeriods = 10;
                if (type !== "add") {
                  totalPeriods = Number(data.installmentCount);
                }
                const bizTotalPlate = getFieldValue("commercialFee");
                // 计算每期需要支付的金额
                const monthlyPayment = Math.floor(
                  bizTotalPlate / (totalPeriods + 2)
                );
                //首付
                const downPayment = Math.floor(
                  bizTotalPlate - monthlyPayment * totalPeriods
                );
                setFieldsValue({ downPayment });
                setFieldsValue({ monthlyPayment });
              }
              return null;
            }}
          </Form.Item>
          <Col span={6}>
            <Form.Item label="首付金额" name="downPayment">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="每期金额" name="monthlyPayment">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="首付日期"
              name="payDay"
              rules={[{ required: true, message: "请输入首付日期" }]}
            >
              <QuickDatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="三者保额"
              name="sanZheBaoE"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入三者保额" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="司机保额"
              name="siJiBaoE"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入司机保额" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="乘客保额"
              name="chengKeBaoE"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入乘客保额" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="车损保额"
              name="cheSunBaoE"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入车损保额" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="备注" name="remark">
              <Input.TextArea placeholder="请输入备注" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="安装设备" name="device">
              {/* <Input placeholder="请输入安装设备" allowClear/> */}
              <Select
                placeholder="请输入安装设备"
                options={deviceType}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="到账日期"
              name="signDay"
              rules={[{ required: true }]}
            >
              <QuickDatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="结算方式"
              name="settlementType"
              rules={[{ required: true, message: "请选择结算方式" }]}
            >
              <Select placeholder="请选择结算方式" options={endType}></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="首付支付方式"
              name="payMethod"
              rules={[{ required: true, message: "请选择首付支付方式" }]}
            >
              <Select
                placeholder="请选择首付支付方式"
                options={payType}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="投保人证件号" name="holderIdCard">
              <Input placeholder="请输入投保人证件号"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="品牌型号" name="moldName">
              <Input placeholder="请输入品牌型号"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="发动机号" name="engineNo">
              <Input placeholder="请输入投保人证件号"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="载客量" name="seatCount">
              <Input placeholder="请输入载客量"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="初登日期" name="registerDate">
              <QuickDatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="评分" name="bizCpicScore" >
              <Input allowClear placeholder="请输入评分" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="评级" name="grade">
              <Select options={gradeList} allowClear placeholder="请输入评级"></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="上传文件(pdf)" name="attachments">
              <UploadFile
                accept=".pdf"
                uploadFilesChange={(successList: any) =>
                  setUploadSuccessList(successList)
                }
                defaultUploadFiles={
                  type === "add"
                    ? []
                    : data.attachments == null
                    ? []
                    : data.attachments.map((item: any) => ({
                        fileName: item.fileName,
                        contentType: item.contentType,
                        key: new URL(item.url).pathname.slice(1),
                        preview: item.url,
                      }))
                }
                canUploadVideo
                limitSize={500}
                multiple
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="支付截图(图片)" name="resources">
              <UploadFile
                accept="image/*"
                uploadFilesChange={(successList: any) =>
                  setUploadPaySuccessList(successList)
                }
                defaultUploadFiles={
                  type === "add"
                    ? []
                    : data.resources == null
                    ? []
                    : data.resources.map((item: any) => ({
                        fileName: item.fileName,
                        contentType: item.contentType,
                        key: new URL(item.url).pathname.slice(1),
                        preview: item.url,
                      }))
                }
                limitSize={500}
                multiple
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {data?.drivingLicense ? (
              <div>
                <Image width="50%" height="50%" src={data?.drivingLicenseUrl} />
              </div>
            ) : (
              <div>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="暂无行驶证信息"
                />
              </div>
            )}
            <Form.Item label="" name="drivingLicense">
              <Upload
                accept="image/*"
                action="/api/v2/resource/upload"
                headers={{
                  access_token: localStorageUtil.getItem("access_token"),
                }}
                beforeUpload={beforeUpload}
                onChange={onUploadChange}
                onDrop={() => {}}
              >
                <PermissionButton
                  // operateType='/car-admin/car-archive/drivingLicenseUpload'
                  loading={uploadLoading}
                  disabled={uploadLoading}
                  type="dashed"
                  icon={<UploadOutlined />}
                  style={{ height: "100px", width: "200px" }}
                >
                  上传行驶证
                </PermissionButton>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default OperateModal;
