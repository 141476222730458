// extracted by mini-css-extract-plugin
export default {"carDiagnose":"carDiagnose_carDiagnose__l7ulB","searchBox":"carDiagnose_searchBox__2Au27","carInfoBox":"carDiagnose_carInfoBox__aAns-","carInfoTitle":"carDiagnose_carInfoTitle__qxTvI","carInfoContent":"carDiagnose_carInfoContent__DHMil","carInfoPerson":"carDiagnose_carInfoPerson__BiVmN","carInfoDriver":"carDiagnose_carInfoDriver__tbivF","personPhone":"carDiagnose_personPhone__4BSOj","driverPhone":"carDiagnose_driverPhone__ihGg1","carMap":"carDiagnose_carMap__wpaHD"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnose\":\"carDiagnose_carDiagnose__l7ulB\",\"searchBox\":\"carDiagnose_searchBox__2Au27\",\"carInfoBox\":\"carDiagnose_carInfoBox__aAns-\",\"carInfoTitle\":\"carDiagnose_carInfoTitle__qxTvI\",\"carInfoContent\":\"carDiagnose_carInfoContent__DHMil\",\"carInfoPerson\":\"carDiagnose_carInfoPerson__BiVmN\",\"carInfoDriver\":\"carDiagnose_carInfoDriver__tbivF\",\"personPhone\":\"carDiagnose_personPhone__4BSOj\",\"driverPhone\":\"carDiagnose_driverPhone__ihGg1\",\"carMap\":\"carDiagnose_carMap__wpaHD\"}";
        // 1743672215312
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  