/**
 * 部标机设备视频回放相关请求
 */
import request from '@/utils/request';
import {
  Jt808PlaybackCommonParams,
  operateJt808Params
} from '@/utils/jtDeviceRequest';

export interface Jt808Command9205RequestParams
  extends Jt808PlaybackCommonParams {
  alarmFlag: number;
  alarmFlag1: number;
}
// 查询部标机设备资源列表
export const jt808Command9205Request = (
  data: Jt808Command9205RequestParams
) => {
  const { url, newData } = operateJt808Params(data, '/808/jt808/9205');
  return request.get(url, { params: newData, headers: {'sn-agent': data.phone} });
};

export interface Jt808Command9201RequestParams
  extends Jt808PlaybackCommonParams {
  playbackMode: number;
  speed: number;
}
// 部标机开始回放
export const jt808Command9201Request = (
  data: Jt808Command9201RequestParams
) => {
  const { url, newData } = operateJt808Params(data, '/808/jt808/9201');
  return request.post(url, newData, {headers: {'sn-agent': data.phone}});
};

export interface Jt808Command9202RequestParams {
  phone: string;
  jt808DeviceId: number;
  channelNo: number;
  cmd: number;
  speed: number;
  time: string;
}
// 控制部标机回放
export const jt808Command9202Request = (
  data: Jt808Command9202RequestParams
) => {
  const { url, newData } = operateJt808Params(data, '/808/jt808/9202');
  return request.post(url, newData, {headers: {'sn-agent': data.phone}});
};

export interface Jt808Command9206RequestParams
  extends Jt808PlaybackCommonParams {
  alarmFlag: number;
  alarmFlag1: number;
  execTiming: number;
}
// 部标机设备资源上传到ftp服务器
export const jt808Command9206Request = (
  data: Jt808Command9206RequestParams
) => {
  const { url, newData } = operateJt808Params(data, '/808/jt808/9206', true);
  return request.post(url, newData, {headers: {'sn-agent': data.phone}});
};

export interface FtpResourceRequestParams {
  plateNumber: string;
  reportedTime?: string;
}
// 查询ftp服务器资源列表
export const ftpResourceRequest = (params: FtpResourceRequestParams) => {
  return request.get('/api/v2/jt808Device/ftp/resouces', {
    params
  });
};

// 绿色汇联信息
export const fatigueInfo = (params: any) => {
  return request.get('/api/v2/fatigue/info', {
    params
  });
};

// 查询ftp服务器资源列表()
export const ftpResourceRequestSingle = (params: any) => {
  return request.get('/api/v2/jt808Device/ftp/resouce', {
    params
  });
};

// 获取ftp服务器资源内容
export const getFtpResourceRequest = (data: string) => {
  return request.post('/api/v2/jt808Device/download', data, {
    responseType: 'blob',
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
  });
};


