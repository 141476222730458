// extracted by mini-css-extract-plugin
export default {"abnormalSummary":"dataDepartment_abnormalSummary__ay6Qh"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"abnormalSummary\":\"dataDepartment_abnormalSummary__ay6Qh\"}";
        // 1743672215194
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  