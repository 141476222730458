/**
 * 支付记录
 */
import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  Popconfirm,
  Modal,
  message,
} from "antd";
import dayjs from "dayjs";
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import { useTable } from "@/utils/useTable";
import {
  batchClearRequest,
  clearSettlementRequest,
  payRecordListRequest,
  reconciliationRequest,
  upsertInsurePayRequest,
  upsertInsurePayRequestPath,
} from "@/service/underwritingRecord";
import styles from "@/styles/pageContent.module.scss";
import {
  DownloadOutlined,
  FileZipOutlined,
  EyeOutlined,
  EditOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { downloadFile } from "@/utils/download";
import PdfPreviewModal from "@/components/pdfPreviewModal";
import DatePicker from "@/components/datePicker";
import { TableRowSelection } from "antd/lib/table/interface";
import AttachmentModal from "../offerRecord/components/attachmentModal";
import UploadPdf from "./components/uploadPdf";
import PermissionButton from "@/components/permissionButton";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { endType, payType } from "../offerRecord/components/underWritingModal";

const PayRecords = () => {
  const [searchParams, setSearchParams] = useState({
    startDay: `${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`,
    endDay: `${dayjs().format("YYYY-MM-DD")}`,
  }); // 搜索相关的参数
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [form] = Form.useForm();
  const [settlementForm] = Form.useForm();
  const [reconciliationForm] = Form.useForm();
  const {
    page,
    limit,
    loading,
    data,
    totalCount,
    onPageChange,
    getData,
    setData,
  } = useTable({
    requestFunc: payRecordListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  const { RangePicker } = DatePicker;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [attachmentModalData, setAttachmentModalData] = useState({}); // 附件弹窗数据
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false); // 附件弹窗是否显示
  const [settlementVisible, setSettlementVisible] = useState(false);
  const [reconciliationVisible, setReconciliationVisible] = useState(false);
  const [settlementData, setSettlementData] = useState<Record<any, any>>({});
  const [pdfItem, setPdfItem] = useState<Record<any, any>>({});

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.startDay = dayjs(params.day[0]).format("YYYY-MM-DD");
    newParams.endDay = dayjs(params.day[1]).format("YYYY-MM-DD");
    newParams.settlementDate = params.settlementDate ? dayjs(params.settlementDate).format('YYYY-MM-DD') : ''
    delete newParams.day;
    if (!newParams.plateNumber) {
      delete newParams.plateNumber;
    }
    setSearchParams(newParams);
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  };

  const onCloseAttachmentModal = () => {
    setAttachmentModalVisible(false);
    onSearch(searchParams);
  };

  //打开关闭查看报价记录附件弹窗
  const openAttachmentModal = (data: any) => {
    setAttachmentModalData(data);
    setAttachmentModalVisible(true);
  };

  const saveMark = (
    e: React.KeyboardEvent<HTMLInputElement>,
    item: any,
    key: string
  ) => {
    if (e.keyCode === 13) {
      const target: any = e.target as any;
      item[key] = target.value;
      //@ts-ignore
      e.target.blur();
      upsertInsurePayRequest({
        ...item,
      }).then((res) => {
        if (res.data?.code === 0) {
          getData();
        }
      });
    }
  };

  const saveDateMark = (e: string, item: any, key: string) => {
    item[key] = e;
    upsertInsurePayRequest({
      ...item,
    }).then((res) => {
      if (res.data?.code === 0) {
        getData();
      }
    });
  };

  const showMarkEdit = (item: any, key: string) => {
    data.forEach((dataItem: any) => {
      if (dataItem.id === item.id) {
        dataItem[key] = true;
      }
    });
    setData([...data]);
  };

  const batchSettment = () => {
    if (!selectedRowKeys.length) {
      message.error("请先选择数据");
      return;
    }
    setSettlementData({ type: "batch", data: selectedRowKeys });
    settlementForm.setFieldValue(
      "settlementDate",
      dayjs(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD")
    );
    setSettlementVisible(true);
  };

  const reconciliation = () => {
    if (!selectedRowKeys.length) {
      message.error("请先选择数据");
      return;
    }
    setSettlementData({ type: "reconciliation", data: selectedRowKeys });
    reconciliationForm.setFieldValue(
      "reconciliationDate",
      dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss")
    );
    setReconciliationVisible(true);
  };

  const changeSellementState = (item: any) => {
    if (item.settlementState == "未结算" || !item.settlementState) {
      setSettlementData({ type: "one", data: item });
      settlementForm.setFieldValue(
        "settlementDate",
        dayjs(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD")
      );
      setSettlementVisible(true);
    }
    if (item.settlementState == "已结算") {
      clearSettlementRequest({ ids: [item.id] }).then((res) => {
        if (res.data.code === 0) {
          message.success("结算状态更改成功");
          getData();
        }
      });
    }
  };

  const statusOk = () => {
    const params = { ...settlementForm.getFieldsValue() };
    if (settlementData.type === "one") {
      let newV = { ...settlementData.data };
      newV.settlementDate = params.settlementDate.format("YYYY-MM-DD");
      newV.settlementState = "已结算";
      upsertInsurePayRequest(newV).then((res) => {
        if (res.data.code == "0") {
          message.success("修改成功");
          setSettlementVisible(false);
          getData();
        }
      });
    }
    if (settlementData.type === "batch") {
      let newV: Record<any, any> = { ids: [...settlementData.data] };
      newV.settlementDate = params.settlementDate.format("YYYY-MM-DD");
      newV.settlementState = "已结算";
      batchClearRequest(newV).then((res) => {
        if (res.data.code == "0") {
          message.success("修改成功");
          setSettlementVisible(false);
          getData();
        }
      });
    }
  };

  const reconciliationOK = () => {
    const params = { ...reconciliationForm.getFieldsValue() };

    let newV: Record<any, any> = { ids: [...settlementData.data] };
    newV.reconciliationDate = params.reconciliationDate.format(
      "YYYY-MM-DD HH:mm:ss"
    );
    newV.reconciliationState = "已对账";
    reconciliationRequest(newV).then((res) => {
      if (res.data.code == "0") {
        message.success("修改成功");
        setReconciliationVisible(false);
        getData();
      }
    });
  };

  // 表格列显示
  const columns = [
    {
      title: "服务单号",
      dataIndex: "insuranceCode",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "车牌号",
      dataIndex: "plateNumber",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "车架号",
      dataIndex: "frameNumber",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "投保人",
      dataIndex: "applicant",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "是否分期",
      dataIndex: "installmentType",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "首付金额",
      dataIndex: "firstFee",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "分期服务费",
      dataIndex: "installmentFee",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "支付日期",
      dataIndex: "date",
      width: 110,
      // render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : '/'
      render: (_: string, item: any) => {
        if (item.dateIsMarkEdit) {
          return (
            <DatePicker
              defaultValue={dayjs(
                item.date || dayjs().format("YYYY-MM-DD"),
                "YYYY-MM-DD"
              )}
              onChange={(e) => {
                saveDateMark(e?.format("YYYY-MM-DD") || "", item, "date");
              }}
            ></DatePicker>
          );
        } else {
          return (
            <div>
              {item.date}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "dateIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "还款金额",
      dataIndex: "fee",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "费用类型",
      dataIndex: "type",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "车辆类型",
      dataIndex: "carType",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "支付方式",
      dataIndex: "payMethod",
      width: 110,
      // render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : '/'
      render: (_: string, item: any) => {
        if (item.payMethodIsMarkEdit) {
          return (
            <Select
              onChange={(e) => {
                saveDateMark(e, item, "payMethod");
              }}
              defaultValue={item.payMethod}
              placeholder="请输入结算方式"
              options={payType}
              maxTagTextLength={5}
            ></Select>
          );
        } else {
          return (
            <div>
              {item.payMethod}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "payMethodIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "还款期数",
      dataIndex: "installmentCount",
      width: 110,
      render: (_: string, item: any) => {
        return item.installmentCount
          ? `${item.installmentCount}-${item.serialNum}`
          : "/";
      },
    },
    {
      title: "市场归属",
      dataIndex: "department",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "渠道经理",
      dataIndex: "channelManager",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "合伙人",
      dataIndex: "partner",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "服务单状态",
      dataIndex: "serviceState",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "结算方式",
      dataIndex: "settlementType",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "优选金额",
      dataIndex: "rewardFee",
      width: 110,
      render: (value: string) =>
        value !== null ? (
          <RenderEllipsisColumn ellipsis={true} text={value} />
        ) : (
          "/"
        ),
    },
    {
      title: "对账状态",
      dataIndex: "reconciliationState",
      width: 110,
      render: (value: string) =>
        value ? (
          <RenderEllipsisColumn ellipsis={true} text={value} />
        ) : (
          "未对账"
        ),
    },
    {
      title: "对账确认时间",
      dataIndex: "reconciliationDate",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "结算状态",
      dataIndex: "settlementState",
      width: 110,
      render: (value: string) =>
        value ? <RenderEllipsisColumn ellipsis={true} text={value} /> : "/",
    },
    {
      title: "评分",
      dataIndex: "bizCpscoreicScore",
      width: 50,
    },
    {
      title: "评级",
      dataIndex: "grade",
      width: 50,
    },
    {
      title: "应结算金额",
      dataIndex: "settlementFee",
      width: 110,
      render: (_: string, item: any) => {
        if (item.settlementFeeIsMarkEdit) {
          return (
            <Input
              defaultValue={item.settlementFee}
              onKeyUp={(e) => {
                saveMark(e, item, "settlementFee");
              }}
            ></Input>
          );
        } else {
          return (
            <div>
              {item.settlementFee}{" "}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "settlementFeeIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "收款人",
      dataIndex: "recipient",
      width: 110,
      render: (_: string, item: any) => {
        if (item.recipientFeeIsMarkEdit) {
          return (
            <Input
              defaultValue={item.recipient}
              onKeyUp={(e) => {
                saveMark(e, item, "recipient");
              }}
            ></Input>
          );
        } else {
          return (
            <div>
              {item.recipient}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "recipientFeeIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "结算日期",
      dataIndex: "settlementDate",
      width: 110,
      render: (_: string, item: any) => {
        if (item.settlementDateIsMarkEdit) {
          return (
            <DatePicker
              defaultValue={dayjs(
                item.settlementDate || dayjs().format("YYYY-MM-DD"),
                "YYYY-MM-DD"
              )}
              onChange={(e) => {
                saveDateMark(
                  e?.format("YYYY-MM-DD") || "",
                  item,
                  "settlementDate"
                );
              }}
            ></DatePicker>
          );
        } else {
          return (
            <div>
              {item.settlementDate}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "settlementDateIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 110,
      render: (_: string, item: any) => {
        if (item.remarkIsMarkEdit) {
          return (
            <Input
              defaultValue={item.remark}
              onKeyUp={(e) => {
                saveMark(e, item, "remark");
              }}
            ></Input>
          );
        } else {
          return (
            <div>
              {item.remark}
              <PermissionButton
                operateType="/car-admin/pay-recodes/edit"
                type="text"
              >
                <EditOutlined
                  onClick={() => {
                    showMarkEdit(item, "remarkIsMarkEdit");
                  }}
                />
              </PermissionButton>
            </div>
          );
        }
      },
    },
    {
      title: "附件",
      dataIndex: "resources",
      width: 300,
      ellipsis: { showTitle: false },
      // render: (_: string, item: any) => {
      //   return (
      //     <Button
      //       type={item.attacheList?.length ? "default" : "link"}
      //       onClick={() => openAttachmentModal(item)}
      //     >
      //       {`${item.attacheList?.length ? "查看" : "上传"}附件`}
      //     </Button>
      //   );
      // },
      render: (value: any, item_: any) => (
        <>
          {/* {value?.map((item: any, index: any) => (
            <div
              key={index}
              style={{
                width: "240px",
                display: "flex",
                border: "1px solid rgb(217,217,217)",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "170px",
                  paddingLeft: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FileZipOutlined style={{ paddingRight: "5px" }} />
                {item.fileName}
              </div>
              <div
                style={{
                  width: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="text"
                  onClick={() => onPdfPreview(item.url, item.fileName)}
                >
                  <EyeOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => downloadFile(item.url, item.fileName)}
                  style={{ paddingLeft: 0 }}
                >
                  <DownloadOutlined />
                </Button>
              </div>
            </div>
          ))} */}
          <div>
            <PermissionButton
              operateType="/car-admin/pay-recodes/edit"
              type="text"
              onClick={() => {
                setPdfItem(item_);
              }}
            >
              {value?.length ? "查看附件" : "上传附件"}
            </PermissionButton>
          </div>
        </>
      ),
    },
    {
      ellipsis: { showTitle: false },
      title: "操作",
      dataIndex: "settlementState",
      width: 110,
      render: (_: string, item: any) => {
        return (
          <Popconfirm
            title={`确认${
              item.settlementState == "已结算" ? "删除结算状态" : "结算"
            }?`}
            onConfirm={() => {
              changeSellementState(item);
            }}
          >
            <PermissionButton
              type="link"
              operateType="/car-admin/pay-recodes/batch-end"
            >
              {" "}
              {item.settlementState == "已结算" ? "删除结算状态" : "结算"}
            </PermissionButton>
          </Popconfirm>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    console.log(123123);
    form.setFieldValue("day", [
      dayjs(`${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`),
      dayjs(`${dayjs().format("YYYY-MM-DD")}`),
    ]);
  }, []);

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form
        form={form}
        onFinish={onSearch}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          day: [
            dayjs(`${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`),
            dayjs(`${dayjs().format("YYYY-MM-DD")}`),
          ],
        }}
      >
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="服务单号" name="insuranceCode">
              <Input placeholder="请输入服务单号" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="时间范围" name="day">
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车架号" name="frameNumber">
              <Input
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="请输入车架号"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="结算方式" name="settlementType">
              <Select
                allowClear
                placeholder="请输入结算方式"
                options={endType}
                mode="multiple"
                maxTagTextLength={5}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="结算状态" name="settlementState">
              <Select
                maxTagTextLength={5}
                mode="multiple"
                allowClear
                placeholder="请输入结算状态"
                options={[
                  { label: "未结算", value: "未结算" },
                  { label: "已结算", value: "已结算" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="服务状态" name="serviceState">
              <Select
                maxTagTextLength={5}
                mode="multiple"
                allowClear
                placeholder="请输入服务状态"
                options={[
                  { label: "未投保", value: "未投保" },
                  { label: "未生效", value: "未生效" },
                  { label: "服务中", value: "服务中" },
                  { label: "服务完成", value: "服务完成" },
                  { label: "转保", value: "转保" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="费用类型" name="type">
              <Select
                maxTagTextLength={5}
                mode="multiple"
                allowClear
                placeholder="请输入费用类型"
                options={[
                  { label: "安全管理费", value: "安全管理费" },
                  { label: "滞纳金", value: "滞纳金" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="结算状态" name="settlementState">
              <Select
                maxTagTextLength={5}
                mode="multiple"
                allowClear
                placeholder="请输入服务状态"
                options={[
                  { label: "未结算", value: "未结算" },
                  { label: "已结算", value: "已结算" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="市场归属" name="department">
              <Input placeholder="请输入市场归属" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="收款人" name="recipient">
              <Input placeholder="请输入收款人" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="对账状态" name="reconciliationState">
              <Select
                allowClear
                placeholder="请输入服务状态"
                options={[
                  { label: "全部", value: "" },
                  { label: "未对账", value: "NUL" },
                  { label: "已对账", value: "已对账" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="结算日期" name="settlementDate">
              <DatePicker
                style={{ width: "100%" }}
                format={"YYYY-MM-DD"}
                allowClear
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <PermissionButton
                  type="primary"
                  htmlType="submit"
                  operateType={"/car-admin/pay-recodes/search"}
                >
                  搜索
                </PermissionButton>
                <ExportButton
                  url={"/api/v2/insure/pay/record/list"}
                  fileName="支付记录"
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey="/car-admin/pay-recodes/export"
                />
                <PermissionButton
                  onClick={() => {
                    batchSettment();
                  }}
                  operateType={"/car-admin/pay-recodes/batch-end"}
                >
                  批量结算
                </PermissionButton>
                <PermissionButton
                  onClick={() => {
                    reconciliation();
                  }}
                  operateType={"/car-admin/pay-recodes/batch-checkout"}
                >
                  批量对账
                </PermissionButton>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {pdfPreviewInfo && (
        <PdfPreviewModal
          title={pdfPreviewInfo?.fileName}
          pdfUrl={pdfPreviewInfo?.url}
          closeModal={() => setPdfPreviewInfo(null)}
        />
      )}
      {attachmentModalVisible && (
        <AttachmentModal
          open={attachmentModalVisible}
          onClose={onCloseAttachmentModal}
          data={attachmentModalData}
        ></AttachmentModal>
      )}
      <Modal
        title="结算"
        open={settlementVisible}
        onOk={statusOk}
        onCancel={() => {
          setSettlementVisible(false);
        }}
      >
        <Form form={settlementForm}>
          <Form.Item
            label="结算日期"
            name={"settlementDate"}
            rules={[{ required: true, message: "请选择日期" }]}
          >
            <DatePicker
              defaultValue={dayjs(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            ></DatePicker>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="对账"
        open={reconciliationVisible}
        onOk={reconciliationOK}
        onCancel={() => {
          setReconciliationVisible(false);
        }}
      >
        <Form form={reconciliationForm}>
          <Form.Item
            label="对账日期"
            name={"reconciliationDate"}
            rules={[{ required: true, message: "请选择日期" }]}
          >
            <DatePicker
              showTime
              defaultValue={dayjs(dayjs(), "YYYY-MM-DD HH:mm:ss")}
            ></DatePicker>
          </Form.Item>
        </Form>
      </Modal>
      {pdfItem.id && (
        <UploadPdf
          item={pdfItem}
          reloadData={getData}
          closeModal={() => {
            setPdfItem({});
          }}
        ></UploadPdf>
      )}
    </Card>
  );
};

export default PayRecords;
