/**
 * 客服任务派发
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, message, Popconfirm, Input, Modal, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import PermissionButton from '@/components/permissionButton';
import OperateModalTask from './components/operateModal';
import { serviceTaskListRequestPath, serviceTaskListRequest, DeleteServiceTask, importServiceTaskRequestPath } from '@/service/serviceTask';
import OperateModal from '../workOrder/components/operateModal';
import { upsertServiceTask } from '@/service/serviceTask';
import { renderTypeColumns } from '@/utils/workOrder';
import { userListRequest } from '@/service/userManage';
import { useDispatch, useSelector } from 'react-redux';
import TaskDetail from './components/taskDetail';
import Thumbnail from '@/components/thumbnail';
import UploadExcel from '@/components/uploadExcel';
import { downloadTempele } from '@/utils/download';

const { RangePicker } = DatePicker;
const ServiceTask = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [addWorkOrder, setAddWorkOrder] = useState<any>({}); // 创建工单的弹窗
  const [isModalOpen, setIsModalOpen] = useState(false); // 指定工单号
  const [isModalOpenItem, setIsModalOpenItem] = useState<any>(); // 指定工单号那条信息的数据
  const [userData, setUserData] = useState([]); // 任务创建人员数据
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: serviceTaskListRequest,
    initPageSize: 40,
    searchParams,
  });
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;

  useEffect(() => {
    getUserData();
  }, []);

  // 获取用户数据
  const getUserData = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUserData(res.data?.data || [])
  }

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams:any = {...params}
    if (params.time) {
      newSearchParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
      delete newSearchParams.time
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 处理状态
  const renderStateType = (value: any) => {
    return <div style={{display:'flex',textAlign:'center'}}>
      <div>
        <div>创建</div>
        <div>{value == null?<div style={{color:'#1890ff'}}>o</div>:<div style={{color:'#52c41a'}}>√</div>}</div>
      </div>
      <div>&nbsp;→&nbsp;</div>
      <div>
        <div>指派</div>
        <div>{value == 1?<div style={{color:'#1890ff'}}>o</div>:value > 1?<div style={{color:'#52c41a'}}>√</div>:<div style={{color:'#ff4d4f'}}>×</div>}</div>
      </div>
      <div>&nbsp;→&nbsp;</div>
      <div>
        <div>待处理</div>
        <div>{value == 2 || value == 3?<div style={{color:'#1890ff'}}>o</div>:value > 3?<div style={{color:'#52c41a'}}>√</div>:<div style={{color:'#ff4d4f'}}>×</div>}</div>
      </div>
      <div>&nbsp;→&nbsp;</div>
      <div>
        <div>正在处理</div>
        <div>{value == 4?<div style={{color:'#1890ff'}}>o</div>:value > 4?<div style={{color:'#52c41a'}}>√</div>:<div style={{color:'#ff4d4f'}}>×</div>}</div>
      </div>
      <div>&nbsp;→&nbsp;</div>
      <div>
        <div>待审核</div>
        <div>{value == 6?<div style={{color:'#1890ff'}}>o</div>:value > 6?<div style={{color:'#52c41a'}}>√</div>:<div style={{color:'#ff4d4f'}}>×</div>}</div>
      </div>
      <div>&nbsp;→&nbsp;</div>
      <div>
        <div>已关闭</div>
        <div>{value == 7?<div style={{color:'#1890ff'}}>o</div>:value > 7?<div style={{color:'#52c41a'}}>√</div>:<div style={{color:'#ff4d4f'}}>×</div>}</div>
      </div>
    </div>
  }

  // 创建工单
  const getWorkOrderId = async (orderId:any,customerId:any,anyData:any) => {
    const params = anyData
    params.orderId = orderId
    const res = await upsertServiceTask(params);
    getData()
  };

  const showModal = (item:any) => {
    setIsModalOpenItem(item)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 指定工单号
  const submit = async (values:any) => {
    const params = isModalOpenItem;
    params.orderCode = values.orderCode
    const res = await upsertServiceTask(params);
    getData()
    setIsModalOpen(false);
  }

  // 任务状态
  const stateData = [
    { label: '未处理', value: 0 },
    { label: '处理中-待呼叫', value: 1 },
    { label: '处理中-已呼叫', value: 2 },
    { label: '处理中-待处理', value: 9 },
    { label: '协助车辆', value: 10 },
    { label: '处理中-已创建工单', value: 3 },
    { label: '处理中-已派工单', value: 4 },
    { label: '处理中-已完成现场处理', value: 5 },
    { label: '处理完成', value: 6 },
    { label: '已退回', value: 7 },
    { label: '已关闭', value: 8 },
  ];

  // 点击车牌跳转车辆诊断
  const skipCarDiagnosis = (value:any) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber:value,beginDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') }
      }
    })
  }

  // 重新分配任务
  const againItem = async(item: any) => {
    const params = {...item}
    params.taskState = 0
    const res = await upsertServiceTask(params);
    if (res.data?.code === 0) {
      message.success('修改成功');
      getData();
    } 
  }

  // 关闭任务
  const closeItem = async(item: any) => {
    const params = {...item}
    params.taskState = 8
    const res = await upsertServiceTask(params);
    if (res.data?.code === 0) {
      message.success('修改成功');
      getData();
    } 
  }

  // 删除单条
  const deleteCarInterviewItem = async(id: number) => {
    const res = await DeleteServiceTask(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 关闭评论
  const taskCommentDetail = () => {
    setOperateInfo({})
    getData();
  }

  // 上传成功的方法
  const uploadSuccess = (resData: any) => {
    if (typeof resData === 'boolean') {
      message.success('导入成功');
      getData();
    } else {
      setOperateInfo({ type: 'repeat', data: resData });
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => skipCarDiagnosis(value)}>{value}</div>
      ),
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '任务类型',
      dataIndex: 'type',
      width: 100,
    },
    {
      title: '分配客服',
      dataIndex: 'assignCustomer',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '任务状态',
      dataIndex: 'taskState',
      width: 180,
      ellipsis: { showTitle: false },
      render: (value: any) => {
        return (
          value == 0 ? <Tag>未处理</Tag> : value == 1 ? <Tag>处理中-待呼叫</Tag> : value == 2 ? <Tag>处理中-已呼叫</Tag> : value == 3 ? <Tag>处理中-已创建工单</Tag> : value == 4 ? <Tag>处理中-已派工单</Tag> : value == 5 ? <Tag>处理中-已完成现场处理</Tag> : value == 6 ? <Tag>处理完成</Tag> : value == 7 ? <Tag>已退回</Tag>: value == 8 ? <Tag>已关闭</Tag> : value == 9 ? <Tag>处理中-待处理</Tag> : value == 10 ? <Tag>协助车辆</Tag> : null
        )
      }
    },
    {
      title: '保司',
      dataIndex: 'insurer',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '渠道',
      dataIndex: 'channel',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '所属公司',
      dataIndex: 'company',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '车辆情况说明',
      dataIndex: 'ex',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '在线状态',
      dataIndex: 'onlineState',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '主问题',
      dataIndex: 'masterProblem',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '次问题',
      dataIndex: 'slaveProblem',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '负责人',
      dataIndex: 'linkman',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '负责人电话',
      dataIndex: 'tel',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '司机',
      dataIndex: 'carDriver',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '司机电话',
      dataIndex: 'carDriverPhone',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '车辆地址',
      dataIndex: 'district',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '影像',
      dataIndex: 'resourceViewDtos',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: any) => (<Thumbnail
        dataSource={value || []}
        downloadImgName="图片"
        downloadVideoName="视频"
      />),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '任务创建人',
      dataIndex: 'creator',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '客服处理人',
      dataIndex: 'dealCustomer',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '处理时间',
      dataIndex: 'beginDealTime',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '处理状态',
      dataIndex: 'state',
      width: 400,
      ellipsis: { showTitle: false },
      render: (value: number) => renderStateType(value),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 170,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {item.taskState == 0 ? <div>
              <PermissionButton operateType='/car-admin/service-task/comment' type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>评论</PermissionButton>
              <PermissionButton operateType='/car-admin/service-task/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
              <Popconfirm
                title="您确定要删除该任务吗?"
                onConfirm={() => deleteCarInterviewItem(item.id)}
              >
                <PermissionButton operateType='/car-admin/service-task/delete' type="link">删除</PermissionButton>
              </Popconfirm>
            </div> : item.taskState == 6 ? <div>
              <PermissionButton operateType='/car-admin/service-task/comment' type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>评论</PermissionButton>
              <Popconfirm
                title="您确定要关闭此任务吗?"
                onConfirm={() => closeItem(item)}
              >
                <PermissionButton operateType='/car-admin/service-task/close' type="link">关闭</PermissionButton>
              </Popconfirm>
            </div> : item.taskState == 7 ? <div>
              <PermissionButton operateType='/car-admin/service-task/comment' type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>评论</PermissionButton>
              <Popconfirm
                title="您确定要重新分派此任务吗?"
                onConfirm={() => againItem(item)}
              >
                <PermissionButton operateType='/car-admin/service-task/again' type="link">重新分派</PermissionButton>
              </Popconfirm>
              <Popconfirm
                title="您确定要关闭此任务吗?"
                onConfirm={() => closeItem(item)}
              >
                <PermissionButton operateType='/car-admin/service-task/close' type="link">关闭</PermissionButton>
              </Popconfirm>
            </div> : <div>
              <PermissionButton operateType='/car-admin/service-task/comment' type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>评论</PermissionButton>
            </div>
            }
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={4}>
            <Form.Item
              label="创建日期"
              name="time"
            >
              <RangePicker allowClear={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder="请输入车架号" allowClear  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="所属公司" name="company">
              <Input placeholder="请输入所属公司" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="渠道" name="channel">
              <Input placeholder="请输入渠道" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="联系人电话" name="tel">
              <Input placeholder="请输入联系人电话" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="任务状态"
              name="state"
            >
              <Select
                options={stateData} 
                placeholder="请选择任务状态"
                showSearch
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="分配客服" name="assignCustomer">
              <Input placeholder="请输入分配客服" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="任务创建人" name="creator">
              <Input placeholder="请输入任务创建人" allowClear />
            </Form.Item>
          </Col>
          {/* <Col span={4}>
            <Form.Item label="工单创建人" name="creatorId">
              <Select
                placeholder="请选择工单创建人"
                options={userData}
                allowClear
                showArrow
                showSearch
                fieldNames={{
                  label: 'nickname',
                  value: 'id'
                }}
                optionFilterProp="nickname"
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item>
              <div className={styles.searchBox} style={{marginTop:'32px'}}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={serviceTaskListRequestPath}
                  fileName='客服任务'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/service-task/export'
                />
                <Button onClick={() => {downloadTempele('excel/template_task_import.xlsx')}}>下载导入模板文件</Button>
                <UploadExcel
                  uploadRequestPath={importServiceTaskRequestPath}
                  uploadPermissionKey="/car-admin/service-task/import"
                  uploadButtonProps={{ children: '批量导入' }}
                  uploadSuccessFunc={uploadSuccess}
                /> 
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/service-task/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModalTask
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {/* 创建工单的弹窗 */}
      {['add', 'edit', 'servicrAdd','servicrTaskAdd'].includes(addWorkOrder?.type) &&
        <OperateModal
          type={addWorkOrder.type}
          workOrderInfo={addWorkOrder.data}
          onClose={() => setAddWorkOrder({})}
          reloadData={() => getData()}
          workId={getWorkOrderId}
        />
      }
      {/* 评论 */}
      {operateInfo?.type === 'detail' &&
        <TaskDetail item={operateInfo.data} onClose={() => taskCommentDetail()} />
      }
      <Modal title="指定工单号" open={isModalOpen} onOk={() => form.submit()} onCancel={handleCancel}>
        <Form
          form={form}
          autoComplete="off"
          onFinish={submit}
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item
            label="工单编号"
            name="orderCode"
          >
            <Input placeholder="请输入工单编号" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}

export default ServiceTask;
