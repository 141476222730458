// 表格列显示
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import Thumbnail from "@/components/thumbnail";
import { Button, Popconfirm } from "antd";
import { QuotationRecordModalType } from "@/pages/offerRecord/components/quotationRecordModal";
import React from "react";
import { marginRight } from "html2canvas/dist/types/css/property-descriptors/margin";
import PermissionButton from "@/components/permissionButton";

interface ItemHandler {
  exportPdfFn: (...args: any[]) => any;
  copyFn: (...args: any[]) => any;
  delFn: (...args: any[]) => any;
  openAttachmentFn: (...args: any[]) => any;
  generateQuotationFn: (...args: any[]) => any;
  repeatFn: (...args: any[]) => any;
}

const useColumns = (handler: ItemHandler) => {
  const {
    exportPdfFn,
    copyFn,
    delFn,
    openAttachmentFn,
    generateQuotationFn,
    repeatFn,
  } = handler;
  return [
    {
      title: "申请报价公司",
      dataIndex: "applyCompany",
      width: 60,
    },
    {
      title: "申请报价人",
      dataIndex: "applyUser",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "报价人",
      dataIndex: "operator",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车牌",
      dataIndex: "licenseNo",
      width: 90,
      render: (value: string, item: any) => (
        <RenderEllipsisColumn text={value} />
      ),
    },
    {
      title: "车架号",
      dataIndex: "carVin",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "发动机号",
      dataIndex: "engineNo",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车型库车辆类型",
      dataIndex: "vehicleFlag",
      width: 80,
      render: (value: number) => {
        if (value == 1) {
          return "机动车";
        }
        if (value == 2) {
          return "特种车";
        }
        if (value == 3) {
          return "新能源车";
        }
        return "未知";
      },
    },
    {
      title: "行驶证车辆类型",
      dataIndex: "vehicleType",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "载重量(kg)",
      dataIndex: "tonCount",
      width: 60,
    },
    {
      title: "牵引质量(kg)",
      dataIndex: "tractionTonCount",
      width: 60,
    },
    {
      title: "载客量",
      dataIndex: "seatCount",
      width: 60,
    },
    {
      title: "品牌型号",
      dataIndex: "moldName",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "是否分期",
      dataIndex: "instalment",
      width: 80,
      render: (value: number) => (
        <RenderEllipsisColumn text={value === 1 ? "分期" : "不分期"} />
      ),
    },
    {
      title: "报价日期",
      dataIndex: "createdAt",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "初登日期",
      dataIndex: "registerDate",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "省份",
      dataIndex: "area",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    // {
    //   title: "城市",
    //   dataIndex: "city",
    //   width: 60,
    //   render: (value: string) => <RenderEllipsisColumn text={value} />,
    // },

    {
      title: "市场归属",
      dataIndex: "carOwnerDepartment",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车辆所有人",
      dataIndex: "carOwnerName",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "投保人证件号",
      dataIndex: "holderIdCard",
      width: 110,
    },
    {
      title: "燃油类型",
      dataIndex: "fuelType",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "设备类型",
      dataIndex: "deviceType",
      width: 80,
    },
    {
      title: "评分",
      dataIndex: "bizCpicScore",
      width: 50,
    },
    {
      title: "评级",
      dataIndex: "grade",
      width: 50,
    },
    {
      title: "NCD折扣系数",
      dataIndex: "rateFactor1",
      width: 65,
    },
    {
      title: "三者保额",
      dataIndex: "sanZhe",
      width: 90,
    },
    {
      title: "三者保费",
      dataIndex: "sanZheBaoFeiPlate",
      width: 50,
    },
    {
      title: "司机保额",
      dataIndex: "siJi",
      width: 80,
    },
    {
      title: "司机保费",
      dataIndex: "siJiBaoFeiPlate",
      width: 50,
    },
    {
      title: "乘客保额",
      dataIndex: "chengKe",
      width: 80,
    },
    {
      title: "乘客保费",
      dataIndex: "chengKeBaoFeiPlate",
      width: 50,
    },
    {
      title: "车损保额",
      dataIndex: "negotiatePrice",
      width: 80,
    },
    {
      title: "车损保费",
      dataIndex: "cheSunBaoFeiPlate",
      width: 50,
    },
    {
      title: "举升费用",
      dataIndex: "liftFee",
      width: 50,
    },
    {
      title: "含三电",
      dataIndex: "threeElectricityFlag",
      width: 60,
      render: (value: number) => (
        <RenderEllipsisColumn text={value ? "是" : "否"} />
      ),
    },
    {
      title: "含自燃",
      dataIndex: "selfBurningFlag",
      width: 60,
      render: (value: number) => (
        <RenderEllipsisColumn text={value ? "是" : "否"} />
      ),
    },
    {
      title: "含举升",
      dataIndex: "liftFlag",
      width: 60,
      render: (value: number) => (
        <RenderEllipsisColumn text={value ? "是" : "否"} />
      ),
    },

    {
      title: "自燃保费",
      dataIndex: "selfBurningFee",
      width: 80,
    },
    {
      title: "总费用",
      dataIndex: "totalPlate",
      width: 70,
    },
    {
      title: "折扣系数",
      dataIndex: "platformFactor",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "参考保司",
      dataIndex: "insure",
      width: 80,
    },
    {
      title: "保司报价",
      dataIndex: "bizTotal",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "联系电话",
      dataIndex: "mobile",
      width: 100,
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 110,
    },
    {
      title: "报价状态",
      dataIndex: "",
      width: 110,
      render: (_: string, item: any) => {
        return item.message ? '失败' : '成功'
      },
    },
    {
      title: "报价来源",
      dataIndex: "source",
      width: 110,
    },
    {
      title: "报价失败原因",
      dataIndex: "message",
      width: 150,
      render: (value: string) => <RenderEllipsisColumn ellipsis text={value} />,
    },
    {
      title: "附件",
      dataIndex: "attachment",
      width: 110,
      render: (_: string, item: any) => {
        return (
          // 有没有附件其实打开的是一个弹窗，但是如果只是更改显示文字的话太不显眼了，所以这里还修改了button的type
          <>
            {item.attacheList?.length ? (
              <Button
                type={item.attacheList?.length ? "default" : "link"}
                onClick={() => openAttachmentFn(item)}
              >
                {`${item.attacheList?.length ? "查看" : "上传"}附件`}
              </Button>
            ) : (
              <PermissionButton type={item.attacheList?.length ? "default" : "link"}
              onClick={() => openAttachmentFn(item)}
              operateType="/car-admin/offer-recode/upload-image"
            >
              {`${item.attacheList?.length ? "查看" : "上传"}附件`}</PermissionButton>
            )}
          </>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 220,
      fixed: "right" as "right",
      render: (_value: string, item: any) => {
        return (
          <div>
            {/*<Button type={"link"}*/}
            {/*        onClick={() => openQuotationRecordModal(QuotationRecordModalType.Edit, item)}>编辑</Button>*/}
            {!item.message && <PermissionButton
              type={"link"}
              onClick={() => exportPdfFn(item)}
              size="small"
              operateType="/car-admin/offer-recode/export-recode"
            >
              导出报价单
            </PermissionButton>}

            <PermissionButton type={"link"} size="small" onClick={() => copyFn(item)}  operateType="/car-admin/offer-recode/copy">
              复制
            </PermissionButton>
            <PermissionButton type={"link"} size="small" onClick={() => repeatFn(item)} operateType="/car-admin/offer-recode/repeat">
              再次报价
            </PermissionButton>
            {!item.message && <PermissionButton
              size="small"
              type={"link"}
              onClick={() => generateQuotationFn(item)}
              operateType="/car-admin/offer-recode/product-contact"
            >
              生成承保单
            </PermissionButton>}
            <Popconfirm
              title="此操作不可恢复，是否确认删除"
              onConfirm={() => {
                delFn(item.id);
              }}
              okText="确认删除"
              cancelText="取消"
            >
              <PermissionButton size="small" type={"link"} operateType="/car-admin/offer-recode/delete">  
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
};

export const useFailColumns = (handler: {openAttachmentFn: (...args: any[]) => void}) => {
  const {
    openAttachmentFn,
  } = handler;
  return [
    {
      title: "申请报价公司",
      dataIndex: "applyCompany",
      width: 60,
    },
    {
      title: "申请报价人",
      dataIndex: "applyUser",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "报价人",
      dataIndex: "operator",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车牌",
      dataIndex: "licenseNo",
      width: 90,
      render: (value: string, item: any) => (
        <RenderEllipsisColumn text={value} />
      ),
    },
    {
      title: "车架号",
      dataIndex: "carVin",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "发动机号",
      dataIndex: "engineNo",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车型库车辆类型",
      dataIndex: "vehicleFlag",
      width: 80,
      render: (value: number) => {
        if (value == 1) {
          return "机动车";
        }
        if (value == 2) {
          return "特种车";
        }
        if (value == 3) {
          return "新能源车";
        }
        return "未知";
      },
    },
    {
      title: "行驶证车辆类型",
      dataIndex: "vehicleType",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "载重量(kg)",
      dataIndex: "tonCount",
      width: 60,
    },
    {
      title: "牵引质量(kg)",
      dataIndex: "tractionTonCount",
      width: 60,
    },
    {
      title: "载客量",
      dataIndex: "seatCount",
      width: 60,
    },
    {
      title: "品牌型号",
      dataIndex: "moldName",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "是否分期",
      dataIndex: "instalment",
      width: 80,
      render: (value: number) => (
        <RenderEllipsisColumn text={value === 1 ? "分期" : "不分期"} />
      ),
    },
    {
      title: "报价日期",
      dataIndex: "createdAt",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "初登日期",
      dataIndex: "registerDate",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "省份",
      dataIndex: "area",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "城市",
      dataIndex: "city",
      width: 60,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },

    {
      title: "市场归属",
      dataIndex: "carOwnerDepartment",
      width: 90,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "车辆所有人",
      dataIndex: "carOwnerName",
      width: 100,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "投保人证件号",
      dataIndex: "holderIdCard",
      width: 110,
    },
    {
      title: "燃油类型",
      dataIndex: "fuelType",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "设备类型",
      dataIndex: "deviceType",
      width: 80,
    },
    {
      title: "评分",
      dataIndex: "bizCpicScore",
      width: 50,
    },
    {
      title: "评级",
      dataIndex: "grade",
      width: 50,
    },
    {
      title: "NCD折扣系数",
      dataIndex: "rateFactor1",
      width: 65,
    },
    {
      title: "三者保额",
      dataIndex: "sanZhe",
      width: 90,
    },
    {
      title: "三者保费",
      dataIndex: "sanZheBaoFeiPlate",
      width: 50,
    },
    {
      title: "司机保额",
      dataIndex: "siJi",
      width: 80,
    },
    {
      title: "司机保费",
      dataIndex: "siJiBaoFeiPlate",
      width: 50,
    },
    {
      title: "乘客保额",
      dataIndex: "chengKe",
      width: 80,
    },
    {
      title: "乘客保费",
      dataIndex: "chengKeBaoFeiPlate",
      width: 50,
    },
    {
      title: "车损保额",
      dataIndex: "negotiatePrice",
      width: 80,
    },
    {
      title: "车损保费",
      dataIndex: "cheSunBaoFeiPlate",
      width: 50,
    },
    {
      title: "举升费用",
      dataIndex: "liftFee",
      width: 50,
    },
    {
      title: "含三电",
      dataIndex: "threeElectricityFlag",
      width: 60,
      render: (value: number) => (
        <RenderEllipsisColumn text={value ? "是" : "否"} />
      ),
    },
    {
      title: "含自燃",
      dataIndex: "selfBurningFlag",
      width: 60,
      render: (value: number) => (
        <RenderEllipsisColumn text={value ? "是" : "否"} />
      ),
    },

    {
      title: "自燃保费",
      dataIndex: "selfBurningFee",
      width: 80,
    },
    {
      title: "总费用",
      dataIndex: "totalPlate",
      width: 70,
    },
    {
      title: "折扣系数",
      dataIndex: "platformFactor",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "参考保司",
      dataIndex: "insure",
      width: 80,
    },
    {
      title: "保司报价",
      dataIndex: "bizTotal",
      width: 80,
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: "联系电话",
      dataIndex: "mobile",
      width: 100,
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 110,
    },
    {
      title: "报价失败原因",
      dataIndex: "message",
      width: 400,
    },
    {
      title: "附件",
      dataIndex: "attachment",
      width: 110,
      render: (_: string, item: any) => {
        return (
          // 有没有附件其实打开的是一个弹窗，但是如果只是更改显示文字的话太不显眼了，所以这里还修改了button的type
          <>
            {item.attacheList?.length && (
              <Button
                type={item.attacheList?.length ? "default" : "link"}
                onClick={() => openAttachmentFn(item)}
              >
                {`${item.attacheList?.length ? "查看" : "上传"}附件`}
              </Button>
            )}
          </>
        );
      },
    },
  ];
};

export default useColumns;
