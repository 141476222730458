/**
 * AT指令参数配置相关接口请求
 */
import request from '@/utils/request';

export interface AtCommandParams {
  sn: string;
  at: string;
}

// AEB AT指令配置(昆仑山3.0设备才支持)
export const atCommandJtKlsRequest = (data: AtCommandParams) => {
  const { sn, at } = data;
  return request.post(`/808/aebs/aebAtConfig/${sn}`, { atStr: at }, {headers: {'sn-agent': sn}});
};

interface AtUpsertRequestParams {
  id: number;
  type: boolean;
  name: string;
  param: string;
  ps: string;
  version: string;
}
// 添加AT指令
export const upsertAtRequest = (data: AtUpsertRequestParams) => {
  return request.post('/api/v2/at/upsert', data);
};

// 获取AT指令列表
export const atListRequest = () => {
  return request.get('/api/v2/at/list');
};

// 删除AT指令
export const deleteAtRequest = (id: number) => {
  return request.delete(`/api/v2/at/${id}`);
};
