/**
 * 异常车辆记录
 */
import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button, Input, Select, Popconfirm, Modal, message } from "antd";
import dayjs from "dayjs";
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import { useTable } from "@/utils/useTable";
import {
  editExceptionRequest,
  exceptionRecordListRequest,
} from "@/service/underwritingRecord";
import styles from "@/styles/pageContent.module.scss";
import DatePicker from "@/components/datePicker";
import PermissionButton from "@/components/permissionButton";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { insureListRequest } from "@/service/insurerManage";
import { channelListRequest } from "@/service/channelRecode";
import { companyListRequest } from "@/service/companyManage";
import { useDispatch } from "react-redux";

const ExceptionRecord = () => {
    const [commercialCompany, setCommercialCompany] = useState<any>([]); // 承保保司
  const [searchParams, setSearchParams] = useState({
    startDay: `${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`,
    endDay: `${dayjs().format("YYYY-MM-DD")}`,
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const [editMarkForm] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData,setData } =
    useTable({
      requestFunc: exceptionRecordListRequest,
      initRequest: true,
      initPageSize: 40,
      searchParams,
    });
  const { RangePicker } = DatePicker;
  const [carBusinessUnit, setCarBusinessUnit] = useState([]); // 获取业务渠道
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [editMarkVisible, setEditMarkVisible] =useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    getCommercialCompany()
    getBusinessUnitType()
    getCateAllCompanyData()
  }, [])

  const editMarkOK = () => {
    const values = editMarkForm.getFieldsValue()
    editExceptionRequest(values).then((res) => {
      if (res.data.code == 0) {
        message.success('保存成功！')
        setEditMarkVisible(false)
        getData()
      }
    })
  }

  const editMarkFn = (item: any) => {
    console.log(item, 'itemmmmmmm')
    editMarkForm.setFieldsValue({remark: item.remark, id: item.id})
    setEditMarkVisible(true)
    
  }

  
    // 获取车队
    const getCateAllCompanyData = async () => {
      const res = await companyListRequest({ page: 1, limit: 20000, name: "" });
      const data = res.data?.data || [];
      //循环遍历data
      const aa: any = [];
      data.forEach((item: any) => {
        aa.push({ label: item.name, value: item.id });
      });
      setAllCompany(aa);
    };

  // 获取业务渠道
  const getBusinessUnitType = async () => {
    // const res = await tagListRequest({
    //   tagSorts: [TagCategoryEnum.businessUnit],
    // });
    const res = await channelListRequest({ page: 1, limit: 50000, name: '' });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name, id } = item;
      tags.push({ label: name, value: id });
    });
    setCarBusinessUnit(tags);
  }

  const getCommercialCompany = async () => {
    const res = await insureListRequest('');
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name, id } = item;
      tags.push({ label: name, value: id });
    });
    setCommercialCompany(tags);
  }

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    console.log(params, '12312')
    const newParams = { ...params };
    newParams.startDate = dayjs(params.day[0]).format("YYYY-MM-DD");
    newParams.endDate = dayjs(params.day[1]).format("YYYY-MM-DD");
    delete newParams.day;
    if (!newParams.plateNumber) {
      delete newParams.plateNumber
    }
    if(!isExport) {
      setSearchParams(newParams);
    }
    
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

    // 点击表格日期跳转到驾驶行为分析页面
    const onCarClick = (plateNumber: string, date: string) => {
      dispatch({
        type: 'addTab',
        payload: {
          tab: '车辆诊断',
          key: '/car-admin/car-diagnosis',
          params: { plateNumber,beginDate: date, endDate: date}
        }
      })
    }

  // 表格列显示
  const columns = [
    {
      title: "问题来源",
      dataIndex: "type",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "车牌号",
      dataIndex: "plateNumber",
      width: 110,
      render: (value: string, item: any) => {return <Button type="link" onClick={() => {onCarClick(item.plateNumber, item.day)}}>{value}</Button>}
    },
    {
      title: "车架号",
      dataIndex: "frameNumber",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "统计日期",
      dataIndex: "day",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "服务状态",
      dataIndex: "serviceState",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "故障名称",
      dataIndex: "errorInfo",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "故障/风险存在天数",
      dataIndex: "maxDays",
      width: 110,
      render: (value: string) => <RenderEllipsisColumn ellipsis={true} text={value}/>
    },
    {
      title: "AEB离线天数",
      dataIndex: "aebOfflineDays",
      width: 110,
      render: (value: string) => <RenderEllipsisColumn ellipsis={true} text={value}/>
    },
    {
      title: "ADAS离线天数",
      dataIndex: "adasOfflineDays",
      width: 110,
      render: (value: string) => <RenderEllipsisColumn ellipsis={true} text={value}/>
    },
    {
      title: "保司",
      dataIndex: "insurance",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "渠道",
      dataIndex: "channel",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "车队",
      dataIndex: "company",
      width: 110,
      render:  (value: string) => {
        return <RenderEllipsisColumn ellipsis={true} text={value}/>
      }
    },
    {
      title: "司机联系方式",
      dataIndex: "driverTel",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "车辆负责人联系方式",
      dataIndex: "manageTel",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 110,
      render: (value: string) => value ? <RenderEllipsisColumn ellipsis={true} text={value}/> : ''
    },

    {
      ellipsis: { showTitle: false },
      title: "操作",
      dataIndex: "operate",
      width: 110,
      render: (_: string, item: any) => {
        return (
          <Button type="link" onClick={() => {editMarkFn(item)}}>编辑备注</Button>
        );
      },
    },
  ];



  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form form={form} onFinish={onSearch} layout="vertical" autoComplete="off" initialValues={{day: [
      dayjs(`${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`),
      dayjs(`${dayjs().format("YYYY-MM-DD")}`),
    ]}}>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="问题来源"
              name="type"
            >
              <Select options={[{label: '问题车', value: '问题车'},{label: '风险车', value: '风险车'},{label: '离线车', value: '离线车'}]}></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="承保保司" name="insuranceIds">
              <Select
                placeholder="请选择承保保司"
                options={commercialCompany}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
                mode="multiple"
                
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="渠道" name="channelIds">
              <Select placeholder="请选择所属业务渠道" options={carBusinessUnit} allowClear showArrow showSearch
                      optionFilterProp="label" mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item label="车队选择" name="companyIds">
              <Select
                mode="multiple"
                showSearch
                placeholder="请选择"
                style={{ width: "100%" }}
                options={allCompany}
                allowClear
                filterOption={(input, option) => {
                  const label = (option?.label || "") as string;
                  return label.indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </Form.Item></Col>
         
          <Col span={6}>
            <Form.Item
              label="时间范围"
              name="day"
            >
              <RangePicker allowClear={false} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          
         
          <Col span={8}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  搜索
                </Button>
                <ExportButton
                  url={'api/v2/car/abnormal/list'}
                  fileName="异常车辆记录"
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey=""
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      <Modal title="编辑备注" open={editMarkVisible} onOk={editMarkOK} onCancel={() => {setEditMarkVisible(false)}}>
        <Form  form={editMarkForm}>
          <Form.Item label="备注" name={'id'} hidden>
            <Input></Input>
          </Form.Item>
          <Form.Item label="备注" name={'remark'}>
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default ExceptionRecord;
