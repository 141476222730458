import {UserOptions} from "jspdf-autotable";

const getServiceItems = (bizTotalPlate: number, instalment: number) => {
  //服务项目
  let serviceItems = ''
  if ((bizTotalPlate >= 7000 && instalment !== 1) || (bizTotalPlate >= 7560 && instalment === 1) ) {
    serviceItems = '安全设备AEBS'
  } else if (bizTotalPlate >= 4000) {
    serviceItems = '安全设备ADAS'
  } else if (bizTotalPlate >= 3000) {
    serviceItems = '安全设备前像'
  } else {
    serviceItems = '安全设备'
  }

  return serviceItems
}
const isParticipateGen = (bizTotalPlate: number) => {
  return (threshold: number) => {
    return bizTotalPlate > threshold ? '✓' : '-'
  }

}

interface pdfConfigProps {
  operator: string,
  createdAt: string,
  licenseNo: string,
  carOwnerName: string,
  bizTotalPlate: number,
  cheSunBaoE: number,
  sanZheBaoE: number,
  sijiBaoE: number,
  chengKeBaoE: number,
  deviceType?: string,
  instalment: number,
  threeElectricityFlag?: boolean,
  selfBurningFlag?: boolean,
  liftFlag?: boolean,
  fuelType: string,
  selfBurningFee: number,
  bizCpicScore: string,
  grade: string,
  totalPlate: string
}

const getPdfConfig = (props: pdfConfigProps, tableWidth?: number): UserOptions => {

  const {
    fuelType,
    operator,
    createdAt,
    licenseNo,
    carOwnerName,
    bizTotalPlate,
    cheSunBaoE,
    sanZheBaoE,
    sijiBaoE,
    chengKeBaoE,
    deviceType,
    instalment,
    threeElectricityFlag,
    selfBurningFlag,
    selfBurningFee,
    grade,
    bizCpicScore,
    liftFlag,
    totalPlate
  } = props

  let specialText = ''

  if (fuelType == '新能源/电' && threeElectricityFlag == false)  {
    specialText = '特别约定：此报价单不含三电'
  }

  if (fuelType == '新能源/电' && threeElectricityFlag == true && selfBurningFlag == false) {
    specialText = '特别约定：单独三电损失免赔、车辆自燃损失免赔。'
  }  

  if (fuelType == '新能源/电' && threeElectricityFlag == true && selfBurningFlag == true) {
    specialText = '特别约定：单独三电损失免赔。 风险减量服务参与方或其允许的驾驶人在使用参加风险减量服务车辆过程中，“由于本车电器、线路、供油系统、供气系统等参加风险减量服务车辆自身原因或所载货物自身原因起火燃烧”，按服务合同“特别约定”负责补偿；风险减量服务参与方需配合提供公安消防部门出具的火灾原因证明；'
  } 

  if (fuelType !== '新能源/电' &&  selfBurningFlag == true) {
    specialText = '特别约定： 风险减量服务参与方或其允许的驾驶人在使用参加风险减量服务车辆过程中，“由于本车电器、线路、供油系统、供气系统等参加风险减量服务车辆自身原因或所载货物自身原因起火燃烧”，按服务合同“特别约定”负责补偿；风险减量服务参与方需配合提供公安消防部门出具的火灾原因证明；'
  } 

  if (liftFlag == true) {
    specialText += '举升过程中造成的标的车辆损失及第三方损失适用绝对免赔率25%或绝对免赔额5000元，绝对免赔取高者，并指定修理厂施救、维修;'
  }

  const serviceItems = getServiceItems(bizTotalPlate, instalment)
  const isParticipate = isParticipateGen(bizTotalPlate)


  const body: any[] = []
  body.push(...[
    [
      {
        content: '中富祥云风险减量服务报价单',
        colSpan: 4,
        styles: {
          fontSize: 25,
          fontStyle: 'bold',
          cellPadding: 8
        }
      }
    ],
    [
      {content: '车牌号',},
      {content: licenseNo,},
      {content: '车主',},
      {content: carOwnerName,}
    ],
    [
      // {
      //   content: '风险减量服务',
      //   colSpan: 2,
      //   styles: {
      //     fillColor: [36, 64, 98],
      //     textColor: [255, 255, 255],
      //   }
      // },
      {content: '评分',},
      {content: bizCpicScore,},
      {content: '评级',},
      {content: grade,},
      // {
      //   content: `评分: ${bizCpicScore}`,
      //   styles: {
      //     fillColor: [36, 64, 98],
      //     textColor: [255, 255, 255],
      //   }
      // },
      // {
      //   content: `评级: ${grade}`,
      //   styles: {
      //     fillColor: [36, 64, 98],
      //     textColor: [255, 255, 255],
      //   }
      // }
    ],
    [
      {
        content: '服务内容',
        colSpan: 2,
        styles: {
          fillColor: [36, 64, 98],
          textColor: [255, 255, 255],
        }
      },
      {
        content: '限额',
        styles: {
          fillColor: [36, 64, 98],
          textColor: [255, 255, 255],
        }
      },
      {
        content: '是否参与',
        styles: {
          fillColor: [36, 64, 98],
          textColor: [255, 255, 255],
        }
      }
    ],
    [
      {content: '服务项目', rowSpan: 2},
      {content: serviceItems},
      {content: '-'},
      {content: isParticipate(3000)}
    ],
    [
      {content: '全年风险管理'},
      {content: '-'},
      {content: isParticipate(3000)}
    ],
    [
      {content: '保证项目', rowSpan: 4},
      {content: '车辆损失'},
      {content: cheSunBaoE},
      {content: cheSunBaoE > 0 ? '✓' : '-'}
    ],
    [
      {content: '第三方责任'},
      {content: sanZheBaoE},
      {content: sanZheBaoE > 0 ? '✓' : '-'}
    ],
    [
      {content: '车上人员责任(司机)'},
      {content: sijiBaoE},
      {content: sijiBaoE > 0 ? '✓' : '-'}
    ],
    [
      {content: '车上人员责任(乘客)'},
      {content: chengKeBaoE},
      {content: chengKeBaoE > 0 ? '✓' : '-'}
    ],
  ])

  //针对AEBS的特殊处理
  // if (deviceType === `AEBS` &&  ((bizTotalPlate >= 7000 && instalment !== 1) || (bizTotalPlate >= 7560 && instalment === 1) )) {
  //   body.push([
  //     {content: '增值项目'},
  //     {content: '产品责任险'},
  //     {content: '5000000'},
  //     {content: '✓'}
  //   ])

  // }


  body.push(...[

    [
      {
        content: '项目服务费(元)',
        styles: {
          textColor: [255, 60, 63]
        },
        colSpan: 2
      },
      {
        content: totalPlate,
        styles: {
          textColor: [255, 60, 63]
        },
        colSpan: 2
      },
    ],
  ])

  if (specialText) {
    body.push(...[
      [{
        content: specialText,
        colSpan: 4,
        styles: {
          halign: 'left',
          fontStyle: 'bold'
  
        }
      }],
    ])
  }

  body.push(...[
    [{
      content: '尊敬的客户：以上试算风险减量服务费是依据您提供给的资料而作出的估算，仅供参考。最终费用及保证限额以合同为准。',
      colSpan: 4,
      styles: {
        halign: 'left',
        fontStyle: 'bold'

      }
    }],
    [
      {content: '报价人'},
      {content: operator},
      {content: '报价日期'},
      {content: createdAt}
    ],
  ])

  return {
    theme: 'grid',
    startY: 50,
    columnStyles: {
      0:{
        cellWidth: 30
      } ,
      1: {
        cellWidth: 60
      },
      2: {
        cellWidth: 35
      },
    },
    styles: {
      font: "shsc",
      valign: 'middle',
      halign: 'center',
      fontSize: 16,
      cellPadding: [3, 2, 2, 2],
    },
    body,
    tableWidth: tableWidth || 'auto'
  }
}

const getInstalmentPdfConfig = (props: { bizTotalPlate: number,deviceType:string,selfBurningFee: number }): UserOptions => {
  const {
    bizTotalPlate,
    deviceType,
    selfBurningFee
  } = props


  const body: any[] = []


  const totalPeriods = 12
  // 计算每期需要支付的金额
  const monthlyPayment = Math.floor((bizTotalPlate + selfBurningFee) / totalPeriods)
  //首付
  const downPayment = Math.floor((bizTotalPlate + selfBurningFee) - monthlyPayment * (totalPeriods - 2))
  //1-10 期
  const loanAmount = monthlyPayment * 10;
  body.push([
    {content:'分期计划',colSpan:4,styles:{fontSize: 18,fontStyle: 'bold'}}
  ])
  body.push([
    {content: '首付', colSpan: 2},
    {content: downPayment, colSpan: 2},
  ])
  for (let i = 1; i <= 10; i++) {
    body.push([
      {content: `第${i}期`, colSpan: 2},
      {content: monthlyPayment, colSpan: 2},
    ])
  }
  body.push([
    {content: '1-10期总计', colSpan: 2},
    {content: loanAmount, colSpan: 2},
  ])
  body.push([
    {content: '总计', colSpan: 2},
    {content: (bizTotalPlate + selfBurningFee), colSpan: 2},
  ])

  return {
    theme: 'grid',
    startY: deviceType === `AEBS`?83.5:65.5,
    margin: {left: 200, right: 10},
    styles: {
      font: "shsc",
      halign: 'center',
      fontSize: 16,
      cellPadding: [3, 2, 2, 2],
    },
    tableWidth: 60,
    body,
    columnStyles: {
      0: {
        cellWidth: 30
      },

    }
  }
}

export {getPdfConfig, getInstalmentPdfConfig}