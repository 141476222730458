// extracted by mini-css-extract-plugin
export default {"toggleBox":"alarmPush_toggleBox__VkQVs","icon":"alarmPush_icon__knYTv","text":"alarmPush_text__axpKn","count":"alarmPush_count__Iw9eR","pushBox":"alarmPush_pushBox__e1hh8","alarmBox":"alarmPush_alarmBox__SdxOi","alarmItem":"alarmPush_alarmItem__02ZAG","title":"alarmPush_title__99rrO","time":"alarmPush_time__u6kLH","clear":"alarmPush_clear__rx9MO"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"toggleBox\":\"alarmPush_toggleBox__VkQVs\",\"icon\":\"alarmPush_icon__knYTv\",\"text\":\"alarmPush_text__axpKn\",\"count\":\"alarmPush_count__Iw9eR\",\"pushBox\":\"alarmPush_pushBox__e1hh8\",\"alarmBox\":\"alarmPush_alarmBox__SdxOi\",\"alarmItem\":\"alarmPush_alarmItem__02ZAG\",\"title\":\"alarmPush_title__99rrO\",\"time\":\"alarmPush_time__u6kLH\",\"clear\":\"alarmPush_clear__rx9MO\"}";
        // 1743672207280
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  