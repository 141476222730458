// AEB参数配置的车速/车身信息获取方式
export const connectTypes = [
  { label: 'CAN', value: 0 },
  { label: '硬线', value: 1 },
  { label: 'DTU-GPS', value: 2 },
  { label: 'IMU-GPS', value: 3 }
];

// AEB参数配置的波特率数据
export const canBaudRates = [
  { label: '1000kbps', value: 1000 },
  { label: '800kbps', value: 800 },
  { label: '500kbps', value: 500 },
  { label: '250kbps', value: 250 },
  { label: '125kbps', value: 125 },
  { label: '100kbps', value: 100 },
  { label: '50kbps', value: 50 },
  { label: '20kbps', value: 20 },
  { label: '10kbps', value: 10 },
  { label: '5kbps', value: 5 }
];

// 是否存在的Radio数据
export const isExistData = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
];

// 记录仪配置灵敏性数据
export const recorderSensitivity = [
  { label: '低', value: 1 },
  { label: '中', value: 2 },
  { label: '高', value: 3 }
];

// CAN接口
export const canX = [
  { label: 'CAN0', value: 0 },
  { label: 'CAN1', value: 1 },
  { label: 'CAN2', value: 2 },
  { label: 'CAN3', value: 3 },
  { label: 'CAN4', value: 4 },
  { label: 'CAN5', value: 5 }
];

// HMW获取方式
export const HMWObtainType = [
  { label: '相机', value: 0 },
  { label: '控制器', value: 1 }
];

// 格式化处理Switch的value
export const formatSwitchValue = (e: any) => {
  return Number(e);
};

// 是否展示角雷达参数区域的判断字段
export const angularRadarJudgeFields = [
  'vehicleWidth2',
  'vehicleLength',
  'vehicleHeight',
  'vehicleFrontToRearAxle',
  'srrBrakingTime',
  'srrBrakingForce',
  'srrDistToVehicleFront',
  'srrDistToVehicleCenter'
];

// 是否展示演示场景参数区域的判断字段
export const demoJudgeFields = [
  'ratioForceToDecelerationDemo',
  'aebDecelerateSetDemo',
  'aebStopDistanceDemo',
  'aebTtcMagicSw',
  'aebTtcDemoSw'
];

// 是否展示右转点刹参数区域的判断字段
export const trBrkJudgeFields = [
  'trBrkEnable',
  'trPoint',
  'trBrkTm',
  'trBrkSp',
  'trBrkDec'
];

// 是否展示后向超声波参数区域的判断字段
export const rearUltrasonicFields = [
  'rearBreakForce',
  'rearBreakForceRatial',
  'rearMaxEnableSpeed',
  'rearStopDistance'
];

// AEB参数配置的所有表单项配置（区分是否为昆仑山3.0设备）
export const getAebConfigParams = (isKlsV3?: boolean) => [
  {
    panelTitle: '双目功能参数',
    panelKey: 'binocular',
    formItems: [
      [
        {
          label: '车道偏离预警使能开关',
          name: 'ldwEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'LDW_Enable'
        },
        {
          label: '双目制动启动车速',
          name: 'minEnableSpeed',
          type: 'inputNumber',
          min: 0,
          max: 120,
          step: 0.1,
          addonAfter: 'km/h',
          at: 'Min_Enable_Speed'
        },
        {
          label: '刹车力度系数',
          name: 'ratioForceToDeceleration',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          at: 'Ratio_Force_To_Deceleration'
        },
        {
          label: 'HMW刹车力度参数',
          name: 'cmsHmwBrakeForceFeelPara',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '秒',
          at: 'CMS_HMW_Brake_Force_Feel_Para'
        },
        {
          label: 'HMW预警时间阈值',
          name: 'cmsHmwWarningTimeThr',
          type: 'inputNumber',
          min: 0,
          max: 3,
          step: 0.1,
          addonAfter: '秒',
          at: 'CMS_HMW_Warning_Time_Thr'
        },
        {
          label: 'HMW刹车时间阈值',
          name: 'cmsHmwBrakeTimeThr',
          type: 'inputNumber',
          min: 0,
          max: 2,
          step: 0.1,
          addonAfter: '秒',
          at: 'CMS_HMW_Brake_Time_Thr'
        },
        {
          label: 'AEB_TTC使能开关',
          name: 'aebTtcEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'AEB_TTC_Enable'
        },
        {
          label: 'AEB紧急制动刹车力度',
          name: 'aebDecelerateSet',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '米/秒^2',
          at: 'AEB_Decelerate_Set'
        },
        {
          label: 'AEB紧急制动刹停距离',
          name: 'aebStopDistance',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '米',
          at: 'AEB_Stop_Distance'
        },
        {
          label: 'AEB紧急制动TTC一级预警时间',
          name: 'aebTtcWarningTimeLevelFirst',
          type: 'inputNumber',
          min: 0,
          max: 5,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '秒',
          at: 'AEB_TTC_Warning_Time_Level_First'
        },
        {
          label: 'AEB紧急制动TTC二级预警时间',
          name: 'aebTtcWarningTimeLevelSecond',
          type: 'inputNumber',
          min: 0,
          max: 5,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '秒',
          at: 'AEB_TTC_Warning_Time_Level_Second'
        },
        {
          label: '最大刹车减速度输出',
          name: 'maxOutputDec',
          type: 'inputNumber',
          min: 0,
          max: 12,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '米/秒^2',
          at: 'Max_Output_dec'
        },
        {
          label: '气刹系统延迟时间',
          name: 'airBrakeDelayTime',
          type: 'inputNumber',
          min: 0,
          max: 1,
          step: 0.1,
          visibleField: 'aebTtcEnable',
          addonAfter: '秒',
          at: 'Air_Brake_Delay_Time'
        }
      ]
    ]
  },
  {
    panelTitle: '起步阻止功能参数',
    panelKey: 'sss',
    formItems: [
      [
        {
          label: '起步阻止刹车使能开关',
          name: 'sssEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'SSS_Enable'
        },
        {
          label: '起步阻止刹车力度',
          name: 'sssBrakeForce',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '米/秒^2',
          visibleField: 'sssEnable',
          at: 'SSS_Brake_Force'
        },
        {
          label: '起步阻止最高有效车速',
          name: 'sssMaxEnableSpeed',
          type: 'inputNumber',
          min: 10,
          max: 40,
          step: 0.1,
          addonAfter: 'km/h',
          visibleField: 'sssEnable',
          at: 'SSS_Max_Enable_Speed'
        },
        {
          label: '起步阻止刹车距离阈值',
          name: 'sssBreakEnableDistance',
          type: 'inputNumber',
          min: 0,
          max: 2.5,
          step: 0.1,
          addonAfter: '米',
          visibleField: 'sssEnable',
          at: 'SSS_Break_Enable_Distance'
        },
        {
          label: '起步阻止预警距离阈值',
          name: 'sssWarningEnableDistance',
          type: 'inputNumber',
          min: 0,
          max: 5,
          step: 0.1,
          addonAfter: '米',
          visibleField: 'sssEnable',
          at: 'SSS_Warning_Enable_Distance'
        },
        {
          label: '起步阻止制动安全距离',
          name: 'sssStopDistance',
          type: 'inputNumber',
          min: 0,
          max: 1,
          step: 0.1,
          addonAfter: '米',
          visibleField: 'sssEnable',
          at: 'SSS_Stop_Distance'
        }
      ]
    ]
  },
  {
    panelTitle: 'IMU功能参数',
    panelKey: 'imu',
    formItems: [
      [
        {
          label: '右转姿态调整参数',
          name: 'trPara',
          type: 'inputNumber',
          min: -360,
          max: 360,
          step: 0.1,
          addonAfter: '度/秒',
          at: 'TR_PARA',
          judgeVisible: true
        },
        {
          label: '右转预警最大时长',
          name: 'trAlmTm',
          type: 'inputNumber',
          min: 0,
          max: 100,
          step: 0.1,
          addonAfter: '秒',
          at: 'TR_ALM_TM',
          judgeVisible: true
        }
      ],
      [
        {
          label: '右转点刹使能开关',
          name: 'trBrkEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'TR_BRK_ENABLE'
        },
        {
          label: '右转识别点',
          name: 'trPoint',
          type: 'inputNumber',
          min: 0,
          max: 1,
          step: 0.1,
          addonAfter: 'm^-1',
          visibleField: 'trBrkEnable',
          at: 'TR_POINT'
        },
        {
          label: '右转点刹时长',
          name: 'trBrkTm',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'trBrkEnable',
          at: 'TR_BRK_TM'
        },
        {
          label: '右转点刹起效速度',
          name: 'trBrkSp',
          type: 'inputNumber',
          min: 30,
          max: 200,
          step: 0.1,
          addonAfter: 'km/h',
          visibleField: 'trBrkEnable',
          at: 'TR_BRK_SP'
        },
        {
          label: '右转点刹最大制动力度',
          name: 'trBrkDec',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '米/秒^2',
          visibleField: 'trBrkEnable',
          at: 'TR_BRK_DEC'
        }
      ]
    ]
  },
  {
    panelTitle: '车辆基本参数',
    panelKey: 'basic',
    formItems: [
      [
        {
          label: '车速获取方式',
          name: 'vehicleSpeedObtainMethod',
          type: 'select',
          optionsData: connectTypes,
          at: 'Vehicle_Speed_Obtain_Method'
        },
        {
          label: '车身状态获取方式',
          name: 'vehicleStateObtainMethod',
          type: 'select',
          optionsData: connectTypes,
          at: 'Vehicle_State_Obtain_Method'
        },
        {
          label: 'HMW获取方式',
          name: 'useReCallHmwEnable',
          type: 'select',
          optionsData: HMWObtainType,
          at: 'Use_ReCalHMW_Enable',
          judgeVisible: true
        }
      ],
      [
        {
          label: '底盘安全策略使能开关',
          name: 'chassisSafeStrategyEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'Chassis_Safe_Strategy_Enable'
        },
        {
          label: '司机介入后冷却期',
          name: 'driverBrakeCoolingTime',
          type: 'inputNumber',
          min: 0,
          max: 60,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'chassisSafeStrategyEnable',
          at: 'Driver_Brake_Cooling_Time'
        },
        {
          label: '控制器刹车冷却期',
          name: 'brakeCoolingTime',
          type: 'inputNumber',
          min: 0,
          max: 60,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'chassisSafeStrategyEnable',
          at: 'Brake_Cooling_Time'
        },
        {
          label: 'HMW阈值夜间偏置',
          name: 'cmsHmwTimeOffsetNight',
          type: 'inputNumber',
          min: 0,
          max: 2,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'chassisSafeStrategyEnable',
          at: 'CMS_HMW_Time_Offset_Night'
        },
        {
          label: '夜间开始时间',
          name: 'cmsHmwTimeOffsetNightStartT',
          type: 'inputNumber',
          min: 12,
          max: 24,
          visibleField: 'chassisSafeStrategyEnable',
          at: 'CMS_HMW_Time_Offset_Night_StartT'
        },
        {
          label: '夜间结束时间',
          name: 'cmsHmwTimeOffsetNightEndT',
          type: 'inputNumber',
          min: 0,
          max: 12,
          visibleField: 'chassisSafeStrategyEnable',
          at: 'CMS_HMW_Time_Offset_Night_EndT'
        }
      ]
    ]
  },
  {
    panelTitle: '角雷达参数配置',
    panelKey: 'angularRadar',
    formItems: [
      [
        {
          label: '车身宽度',
          name: 'vehicleWidth2',
          type: 'inputNumber',
          min: 1,
          max: 5,
          step: 0.1,
          addonAfter: '米',
          at: 'VehicleWidth'
        },
        {
          label: '车身长度',
          name: 'vehicleLength',
          type: 'inputNumber',
          min: 1,
          max: 20,
          step: 0.1,
          addonAfter: '米',
          at: 'VehicleLength'
        },
        {
          label: '车身高度',
          name: 'vehicleHeight',
          type: 'inputNumber',
          min: 0.5,
          max: 5,
          step: 0.1,
          addonAfter: '米',
          at: 'VehicleHeight'
        },
        {
          label: '车头到后轴距离',
          name: 'vehicleFrontToRearAxle',
          type: 'inputNumber',
          min: 1,
          max: 20,
          step: 0.1,
          addonAfter: '米',
          at: 'VehicleFrontToRearAxle'
        },
        {
          label: '刹车时机',
          name: 'srrBrakingTime',
          type: 'inputNumber',
          min: 0,
          max: 100,
          step: 0.1,
          at: 'SrrBrakingTime'
        },
        {
          label: '刹车力度',
          name: 'srrBrakingForce',
          type: 'inputNumber',
          min: 0,
          max: 100,
          step: 0.1,
          at: 'SrrBrakingForce'
        },
        {
          label: '角雷达到车头轴距离',
          name: 'srrDistToVehicleFront',
          type: 'inputNumber',
          min: 0,
          max: 20,
          step: 0.1,
          addonAfter: '米',
          at: 'SrrDistToVechicleFront'
        },
        {
          label: '角雷达到车辆中心距离',
          name: 'srrDistToVehicleCenter',
          type: 'inputNumber',
          min: 0,
          max: 5,
          step: 0.1,
          addonAfter: '米',
          at: 'SrrDistToVechicleCenter'
        }
      ]
    ]
  },
  {
    panelTitle: '演示场景刹车参数配置',
    panelKey: 'demo',
    formItems: [
      [
        {
          label: '西安渣土车静态演示Demo开关',
          name: 'aebTtcMagicSw',
          type: 'switch',
          getValueFromEvent: formatSwitchValue,
          disabledField: 'aebTtcDemoSw',
          at: 'AEB_TTC_MAGIC'
        },
        {
          label: '正式版静态刹停演示Demo开关',
          name: 'aebTtcDemoSw',
          type: 'switch',
          getValueFromEvent: formatSwitchValue,
          extra: 'V2版本开启将强制关闭V1版本',
          at: 'AEB_TTC_DEMO'
        }
      ],
      [
        {
          label: '正式版刹车力度系数',
          name: 'ratioForceToDecelerationDemo',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          visibleField: ['aebTtcMagicSw', 'aebTtcDemoSw'],
          at: 'Ratio_Force_To_Deceleration_demo'
        },
        {
          label: '正式版刹停目标距离',
          name: 'aebStopDistanceDemo',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          visibleField: ['aebTtcMagicSw', 'aebTtcDemoSw'],
          at: 'AEB_Stop_Distance_demo'
        },
        {
          label: '正式版刹车时机',
          name: 'aebDecelerateSetDemo',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          visibleField: ['aebTtcMagicSw', 'aebTtcDemoSw'],
          at: 'AEB_Decelerate_Set_demo'
        }
      ]
    ]
  },
  {
    panelTitle: '基础参数',
    panelKey: 'common',
    formItems: [
      [
        {
          label: '轮速系数',
          name: 'wheelSpeedCoefficient',
          type: 'inputNumber',
          min: 0,
          max: 10000,
          at: 'WheelSpeed_Coefficient'
        },
        {
          label: '车身宽度',
          name: 'vehicleWidth',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '米',
          at: 'Vechile_Width',
          hide: isKlsV3 // 昆仑山3.0设备不显示此表单项
        }
      ]
    ]
  },
  {
    panelTitle: '功能开关配置',
    panelKey: 'enable',
    formItems: [
      [
        {
          label: '制动功能开关',
          name: 'aebCmsEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'AEB_CMS_Enable'
        },
        {
          label: 'HMW动态刹车阈值策略开关',
          name: 'hmwDynamicThrEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'HMW_Dynamic_Thr_Enable'
        },
        {
          label: '小屏幕控制开关',
          name: 'displayerSwitchEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'Displayer_Switch_Enable'
        },
        {
          label: '缓收刹车功能开关',
          name: 'retractBrakeEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'Retract_Brake_Enable'
        }
      ]
    ]
  },
  {
    panelTitle: '缓刹参数',
    panelKey: 'cms',
    formItems: [
      [
        {
          label: 'HMW缓刹开关',
          name: 'cmsHmwEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'CMS_HMW_Enable'
        },
        {
          label: 'HMW刹车安全距离',
          name: 'cmsHmwSafeDistance',
          type: 'inputNumber',
          min: 0,
          max: 20,
          step: 0.1,
          addonAfter: '米',
          visibleField: 'cmsHmwEnable',
          at: 'CMS_HMW_Safe_distance',
          judgeVisible: true
        }
      ],
      [
        {
          label: '相机CMS紧急制动开关',
          name: 'cmsTtcEnable',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'CMS_TTC_Enable'
        },
        {
          label: 'CMS TTC缓刹刹车时间阈值',
          name: 'cmsTtcBrakeTimeThr',
          type: 'inputNumber',
          min: 0,
          max: 3,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'cmsTtcEnable',
          at: 'CMS_TTC_Brake_Time_Thr'
        },
        {
          label: 'CMS TTC缓刹固定刹车力度值（体感）',
          name: 'cmsTtcBrakeForceFeelPara',
          type: 'inputNumber',
          min: 0,
          max: 3,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'cmsTtcEnable',
          at: 'CMS_TTC_Brake_Force_Feel_Para'
        },
        {
          label: 'CMS TTC缓刹一级预警时间',
          name: 'cmsTtcWarningTimeLevelFirst',
          type: 'inputNumber',
          min: 0.0,
          max: 4.4,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'cmsTtcEnable',
          at: 'CMS_TTC_Warning_Time_Level_First'
        },
        {
          label: 'CMS TTC缓刹二级预警时间',
          name: 'cmsTtcWarningTimeLevelSecond',
          type: 'inputNumber',
          min: 0.0,
          max: 3.8,
          step: 0.1,
          addonAfter: '秒',
          visibleField: 'cmsTtcEnable',
          at: 'CMS_TTC_Warning_Time_Level_Second'
        }
      ]
    ]
  },
  {
    panelTitle: '起步阻止参数',
    panelKey: 'sBlock',
    formItems: [
      [
        {
          label: '前向雷达安装位置距离车身左右转轮距离',
          name: 'sssFrFlInstallDistanceToSide',
          type: 'inputNumber',
          min: 0,
          max: 1,
          step: 0.1,
          addonAfter: '米',
          at: 'SSS_FR_FL_Install_Distance_To_Side'
        },
        {
          label: '默认转向角度',
          name: 'sssDefaultTurnAngle',
          type: 'inputNumber',
          min: 0,
          max: 60,
          step: 0.1,
          addonAfter: '度',
          at: 'SSS_Default_Turn_Angle'
        }
      ]
    ]
  },
  {
    panelTitle: '后向超声波参数',
    panelKey: 'rearUltrasonic',
    formItems: [
      [
        {
          label: '超声波雷达倒车刹车力度',
          name: 'rearBreakForce',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: 'm/s2',
          at: 'REAR_BREAK_FORCE'
        },
        {
          label: '超声波雷达倒车刹车力度系数',
          name: 'rearBreakForceRatial',
          type: 'inputNumber',
          min: 0,
          max: 1,
          step: 0.1,
          at: 'REAR_BREAK_FORCE_RATIAL'
        },
        {
          label: '超声波雷达倒车最大使能车速',
          name: 'rearMaxEnableSpeed',
          type: 'inputNumber',
          min: 0,
          max: 100,
          step: 0.1,
          addonAfter: 'km/h',
          at: 'REAR_MAX_ENABLE_SPEED'
        },
        {
          label: '超声波雷达倒车刹停距离',
          name: 'rearStopDistance',
          type: 'inputNumber',
          min: 0,
          max: 10,
          step: 0.1,
          addonAfter: '米',
          at: 'REAR_STOP_DISTANC'
        }
      ]
    ]
  },
  {
    panelTitle: '底盘安全策略',
    panelKey: 'chassis',
    formItems: [
      [
        {
          label: '最长刹车保持时间',
          name: 'maxBrakeKeepTime',
          type: 'inputNumber',
          min: 0,
          max: 30,
          step: 0.1,
          addonAfter: '秒',
          at: 'Max_Brake_Keep_Time'
        }
      ]
    ]
  },
  {
    panelTitle: '电磁阀参数',
    panelKey: 'pwm',
    formItems: [
      [
        {
          label: '电磁阀使能开关',
          name: 'pwmMagneticValveSwitch',
          type: 'switch',
          getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
          at: 'PWM_magnetic_valve_Switch'
        },
        {
          label: '电磁阀最小操作时间参数',
          name: 'pwmMagneticValveT',
          type: 'inputNumber',
          min: 0,
          max: 50,
          addonAfter: 'ms',
          visibleField: 'pwmMagneticValveSwitch',
          at: 'PWM_magnetic_valve_T'
        },
        {
          label: '电磁阀操作周期参数',
          name: 'pwmMagneticValveN',
          type: 'inputNumber',
          min: 0,
          max: 5,
          addonAfter: '次数',
          visibleField: 'pwmMagneticValveSwitch',
          at: 'PWM_magnetic_valve_N'
        },
        {
          label: '电磁阀比例系数',
          name: 'pwmMagneticValveScale',
          type: 'inputNumber',
          min: 0,
          max: 100,
          visibleField: 'pwmMagneticValveSwitch',
          at: 'PWM_magnetic_valve_Scale'
        }
      ]
    ]
  }
];

// 拉线电机表单配置
export const cableMotorFormConfig = [
  [
    {
      label: '使能开关',
      name: 'cableMotorEnable',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      at: 'CABLE_MOTOR_ENABLE'
    },
    {
      label: 'CAN车速问答方式使能',
      name: 'canSpeedRequestEnable',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      at: 'CAN_SPEED_REQUEST_ENABLE'
    }
  ],
  [
    {
      label: '刹车比例系数',
      name: 'cableMotorForceRatio',
      type: 'inputNumber',
      min: 0,
      max: 10,
      at: 'CABLE_MOTOR_FORCE_RATIO'
    },
    {
      label: '恢复时长',
      name: 'cableMotorResetTime',
      type: 'inputNumber',
      min: 0,
      max: 2000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_RESET_TIME'
    },
    {
      label: '最大控制时长',
      name: 'cableMotorMaxTime',
      type: 'inputNumber',
      min: 0,
      max: 2000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_MAX_TIME'
    },
    {
      label: '拉紧时间',
      name: 'cableMotorTightenTime',
      type: 'inputNumber',
      min: 0,
      max: 2000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_TIGHTEN_TIME'
    },
    {
      label: '踏板空程时长',
      name: 'cableMotorPedalIdleStroke',
      type: 'inputNumber',
      min: 0,
      max: 2000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_PEDAL_IDLE_STROKE'
    },
    {
      label: '收紧踏板的单步时长',
      name: 'cableMotorBackwardRotation',
      type: 'inputNumber',
      min: 0,
      max: 255,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_BACKWARD_ROTATION'
    },
    {
      label: '释放踏板的单步时长',
      name: 'cableMotorForwardRotation',
      type: 'inputNumber',
      min: 0,
      max: 255,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_FORWARD_ROTATION'
    },
    {
      label: '再次制动门限值',
      name: 'cableMotorDecelThld',
      type: 'inputNumber',
      min: 0,
      max: 10,
      addonAfter: 'm/s2',
      at: 'CABLE_MOTOR_DECEL_THLD'
    },
    {
      label: '再次制动的拉线时长比例',
      name: 'cableMotorFurtherStrength',
      type: 'inputNumber',
      min: 0,
      max: 10,
      at: 'CABLE_MOTOR_FURTHER_STRENGTH'
    },
    {
      label: '制动变为零之后拉线电机保持时长',
      name: 'cableMotorKeepDuration',
      type: 'inputNumber',
      min: 0,
      max: 1000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_KEEP_DURATION'
    },
    {
      label: '紧急制动的比例系数',
      name: 'cableMotorEmergencyBrakeRatio',
      type: 'inputNumber',
      min: 0,
      max: 10,
      at: 'CABLE_MOTOR_EMERGENCY_BRAKE_RATIO'
    },
    {
      label: '车辆转向灯转换间隔值',
      name: 'vehicleTurnLightIntervalCnt',
      type: 'inputNumber',
      min: 0,
      max: 100,
      at: 'VEHICLE_TURN_LIGHT_INTERVAL_CNT',
      extra:
        '部分车辆的转向灯工作时，是间隔信号而非一直保持的信号，就是1->0->1的变化过程，中间1和0的保持时间间隔不同，此参数即为配合不同车辆转向灯工作间隔而配置的。',
      judgeRequired: true
    }
  ],
  [
    {
      label: 'demo模式下踏板空行程时间',
      name: 'cableMotorPedalIdleStrokeDemo',
      type: 'inputNumber',
      min: 0,
      max: 2000,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_PEDAL_IDLE_STROKE_DEMO',
      span: 12,
      extra:
        '此参数为在静态刹停演示模式下，所配置的在刹车制动之前将刹车踏板的空程时间排除在有效刹车的控制之外。一般此参数要比正常模式下的踏板空行程时间要大。',
      judgeRequired: true
    },
    {
      label: 'demo模式下拉线电机反转时长',
      name: 'cableMotorBackwardRotationDemo',
      type: 'inputNumber',
      min: 0,
      max: 255,
      addonAfter: 'ms',
      at: 'CABLE_MOTOR_BACKWARD_ROTATION_DEMO',
      span: 12,
      extra:
        '此参数为在静态刹停演示模式下，配置的收紧踏板的单步时长，一般此参数要比正常模式下的收紧踏板单步时长要大。',
      judgeRequired: true
    }
  ],
  [
    {
      label: '拉线电机工作PMW设定(仅用于力辉电机)',
      name: 'cableMotorWorkingPmwVal',
      type: 'inputNumber',
      min: 0,
      max: 100,
      at: 'CABLE_MOTOR_WORKING_PMW_VAL',
      span: 12,
      extra:
        '此参数为力辉电机驱动方式的工作的PMW值设定，设置数值越大，驱动小板输出给拉线电机驱动的电流和电压越大，拉线电机力度越大。',
      judgeRequired: true
    },
    {
      label: '拉线电机回弹保持PMW设定(仅用于力辉电机)',
      name: 'cableMotorHoldingPmwVal',
      type: 'inputNumber',
      min: 0,
      max: 100,
      at: 'CABLE_MOTOR_HOLDING_PMW_VAL',
      span: 12,
      extra:
        '此参数为力辉电机驱动方式的拉线保持的的PMW值设定，设置数值越大，驱动小板输出给拉线电机驱动的电流和电压越大，拉线电机力度越大。',
      judgeRequired: true
    }
  ]
];

// 传感器配置的所有表单项配置（区分是否为昆仑山3.0设备）
export const getSensorConfigParams = (isKlsV3?: boolean) => [
  [
    {
      label: '项目类型',
      name: 'projectTypeParam',
      type: 'inputNumber',
      min: 0,
      max: 255,
      span: 6
    },
    {
      label: '产品类型',
      name: 'productTypeParam',
      type: 'inputNumber',
      min: 0,
      max: 255,
      span: 6
    },
    {
      label: '角雷达场景开关',
      name: 'demoSwitch',
      type: 'switch',
      getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
      span: 6
    }
  ],
  [
    {
      label: '4g开关',
      name: 'cfg4gSwitch',
      type: 'switch',
      initialValue: 1,
      disabled: true,
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '4g厂商',
      name: 'cfg4gProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    },
    {
      label: 'gps开关',
      name: 'cfgGpsSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: 'gps厂商',
      name: 'cfgGpsProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    }
  ],
  [
    {
      label: '双目开关',
      name: 'cfgDblCamSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '双目厂商',
      name: 'cfgDblCamProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    },
    {
      label: '单目开关',
      name: 'cfgSglCamSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '单目厂商',
      name: 'cfgSglCamProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    }
  ],
  [
    {
      label: '前向超声波开关',
      name: 'cfgUltraFrontSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '前向超声波厂商',
      name: 'cfgUltraFrontProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '前向超声波数目',
      name: 'cfgUltraFrontNum',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '前向控制器编号',
      name: 'cfgUltraFrontMcuNo',
      type: 'inputNumber',
      min: 1,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '前向1号雷达是否存在',
      name: 'cfgUltraFrontRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '前向2号雷达是否存在',
      name: 'cfgUltraFrontRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '前向3号雷达是否存在',
      name: 'cfgUltraFrontRdr3Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '前向4号雷达是否存在',
      name: 'cfgUltraFrontRdr4Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '前向5号雷达是否存在',
      name: 'cfgUltraFrontRdr5Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '前向6号雷达是否存在',
      name: 'cfgUltraFrontRdr6Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '后向超声波开关',
      name: 'cfgUltraEndSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '后向超声波厂商',
      name: 'cfgUltraEndProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '后向超声波数目',
      name: 'cfgUltraEndNum',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '后向控制器编号',
      name: 'cfgUltraEndMcuNo',
      type: 'inputNumber',
      min: 1,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '后向1号雷达是否存在',
      name: 'cfgUltraEndRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '后向2号雷达是否存在',
      name: 'cfgUltraEndRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '后向3号雷达是否存在',
      name: 'cfgUltraEndRdr3Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '后向4号雷达是否存在',
      name: 'cfgUltraEndRdr4Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '后向5号雷达是否存在',
      name: 'cfgUltraEndRdr5Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '后向6号雷达是否存在',
      name: 'cfgUltraEndRdr6Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '左侧超声波开关',
      name: 'cfgUltraLeftSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '左侧超声波厂商',
      name: 'cfgUltraLeftProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '左侧超声波数目',
      name: 'cfgUltraLeftNum',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '左侧控制器编号',
      name: 'cfgUltraLeftMcuNo',
      type: 'inputNumber',
      min: 1,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '左侧1号雷达是否存在',
      name: 'cfgUltraLeftRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '左侧2号雷达是否存在',
      name: 'cfgUltraLeftRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '左侧3号雷达是否存在',
      name: 'cfgUltraLeftRdr3Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '左侧4号雷达是否存在',
      name: 'cfgUltraLeftRdr4Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '左侧5号雷达是否存在',
      name: 'cfgUltraLeftRdr5Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '左侧6号雷达是否存在',
      name: 'cfgUltraLeftRdr6Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '右侧超声波开关',
      name: 'cfgUltraRightSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '右侧超声波厂商',
      name: 'cfgUltraRightProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '右侧超声波数目',
      name: 'cfgUltraRightNum',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '右侧控制器编号',
      name: 'cfgUltraRightMcuNo',
      type: 'inputNumber',
      min: 1,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '右侧1号雷达是否存在',
      name: 'cfgUltraRightRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '右侧2号雷达是否存在',
      name: 'cfgUltraRightRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '右侧3号雷达是否存在',
      name: 'cfgUltraRightRdr3Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '右侧4号雷达是否存在',
      name: 'cfgUltraRightRdr4Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '右侧5号雷达是否存在',
      name: 'cfgUltraRightRdr5Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '右侧6号雷达是否存在',
      name: 'cfgUltraRightRdr6Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '车前毫米波开关',
      name: 'cfgMmwFrontSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '车前毫米波厂商',
      name: 'cfgMmwFrontProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '车前毫米波数量',
      name: 'cfgMmwFrontNum',
      type: 'inputNumber',
      min: 0,
      max: 3,
      span: 6
    },
    {
      label: '车前毫米波1号雷达是否存在',
      name: 'cfgMmwFrontRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '车后毫米波开关',
      name: 'cfgMmwEndSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '车后毫米波厂商',
      name: 'cfgMmwEndProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '车后毫米波数量',
      name: 'cfgMmwEndNum',
      type: 'inputNumber',
      min: 0,
      max: 3,
      span: 6
    },
    {
      label: '车后毫米波1号雷达是否存在',
      name: 'cfgMmwEndRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '车左毫米波开关',
      name: 'cfgMmwLeftSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '车左毫米波厂商',
      name: 'cfgMmwLeftProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '车左毫米波数量',
      name: 'cfgMmwLeftNum',
      type: 'inputNumber',
      min: 0,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '车左毫米波1号雷达是否存在',
      name: 'cfgMmwLeftRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '车左毫米波2号雷达是否存在',
      name: 'cfgMmwLeftRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: '车右毫米波开关',
      name: 'cfgMmwRightSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '车右毫米波厂商',
      name: 'cfgMmwRightProducer',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    },
    {
      label: '车右毫米波数量',
      name: 'cfgMmwRightNum',
      type: 'inputNumber',
      min: 0,
      max: 3,
      span: 6
    }
  ],
  [
    {
      label: '车右毫米波1号雷达是否存在',
      name: 'cfgMmwRightRdr1Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    },
    {
      label: '车右毫米波2号雷达是否存在',
      name: 'cfgMmwRightRdr2Exists',
      type: 'radioGroup',
      optionsData: isExistData,
      span: 6
    }
  ],
  [
    {
      label: 'imu开关',
      name: 'cfgImuSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: 'imu厂商',
      name: 'cfgImuProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    },
    {
      label: '记录仪开关',
      name: 'cfgCarDvrSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '记录仪厂商',
      name: 'cfgCarDvrProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    }
  ],
  [
    {
      label: '执行机构开关',
      name: 'cfgActuatorSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '执行机构厂商',
      name: 'cfgActuatorProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    },
    {
      label: '执行机构类型',
      name: 'cfgActuatorStructType',
      type: 'inputNumber',
      min: 0,
      max: 7,
      span: 6
    }
  ],
  [
    {
      label: '小屏幕开关',
      name: 'screenSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '小屏幕厂商',
      name: 'screenProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    },
    {
      label: '蓝牙开关',
      name: 'btSwitch',
      type: 'switch',
      getValueFromEvent: formatSwitchValue,
      span: 6
    },
    {
      label: '蓝牙厂商',
      name: 'btProducer',
      type: 'inputNumber',
      min: 0,
      max: 15,
      span: 6
    }
  ]
];

// 相机参数配置的表单项配置（区分是否为昆仑山3.0设备）
export const getCameraConfigParams = (isKlsV3?: boolean) => [
  [
    {
      label: '相机夜晚非标障碍物识别开关',
      name: isKlsV3 ? 'recognitionSwitch' : 'nightNonStandardObsSwitch',
      type: 'switch',
      getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
      span: 24
    },
    {
      label: '相机存图开关',
      name: isKlsV3 ? 'imgSwitch' : 'froImageStorageSwitch',
      type: 'switch',
      getValueFromEvent: isKlsV3 ? formatSwitchValue : null,
      span: 24
    }
  ]
];

// 车身类型表单配置
export const carTypeFormConfig = [
  [
    {
      label: '车身类型',
      name: 'type',
      type: 'inputNumber',
      min: 1,
      max: 84,
      precision: 0,
      span: 24
    }
  ]
];

// GPS上报频率表单配置
export const gpsFrequencyFormConfig = [
  [
    {
      label: '上报频率',
      name: 'frequency',
      type: 'inputNumber',
      min: 5,
      max: 60,
      precision: 0,
      span: 24
    }
  ]
];

// 记录仪配置表单配置
export const recorderFormConfig = [
  [
    {
      label: '是否打开gsensor触发视频上传',
      name: 'enable',
      type: 'switch',
      span: 24
    },
    {
      label: '灵敏性',
      name: 'sensitivity',
      type: 'select',
      optionsData: recorderSensitivity,
      span: 24
    },
    {
      label: '拍摄时长',
      name: 'duration',
      type: 'inputNumber',
      min: 1,
      max: 60,
      addonAfter: '秒',
      span: 24
    },
    {
      label: 'FCW触发开关',
      name: 'fcwEnable',
      type: 'switch',
      span: 24
    },
    {
      label: 'HMW触发开关',
      name: 'hmwEnable',
      type: 'switch',
      span: 24
    },
    {
      label: 'LDW触发开关',
      name: 'ldwEnable',
      type: 'switch',
      span: 24
    },
    {
      label: 'AEB触发开关',
      name: 'aebEnable',
      type: 'switch',
      span: 24,
      initialValue: true
    },
    {
      label: '是否允许系统自动配置',
      name: 'allowAutoConfig',
      type: 'switch',
      span: 24,
      initialValue: true
    }
  ]
];
