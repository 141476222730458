// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","liveVideoPlaceholder":"liveVideo_liveVideoPlaceholder__UihKj","liveVideo":"liveVideo_liveVideo__D+0EN","toolbar":"liveVideo_toolbar__pEecN","video":"liveVideo_video__SYgrU"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"liveVideoPlaceholder\":\"liveVideo_liveVideoPlaceholder__UihKj\",\"liveVideo\":\"liveVideo_liveVideo__D+0EN\",\"toolbar\":\"liveVideo_toolbar__pEecN\",\"video\":\"liveVideo_video__SYgrU\"}";
        // 1743672218341
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  