/**
 * 设备投保记录
 */
import React, { useEffect, useState, Fragment } from 'react';
import { Button, Card, Col, Form, Input, Popconfirm, Row, Select, Table, Tag, message } from 'antd';
import styles from '@/styles/pageContent.module.scss';
import CommonTable from '@/components/commonTable';
import { useTable } from '@/utils/useTable';
import ExportButton from '@/components/exportButton';
import { DeleteProductInsureRecord, importProductInsureRecord, productInsureRecordListRequest, productInsureRecordListRequestPath } from '@/service/productInsureRecord';
import UploadExcel from '@/components/uploadExcel';
import PermissionButton from '@/components/permissionButton';
import OperateModal from './components/operateModal';
import ProductInsureModal from './components/productInsure'
import dayjs from 'dayjs';
import { downloadTempele } from '@/utils/download';

interface TotalTableProps {
  requestMethod: any; // 请求方法
}

const productInsureRecord = (props: TotalTableProps) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: productInsureRecordListRequest,
    initPageSize: 40,
    searchParams,
  });


  // 处理搜索参数 
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams: any = { ...params }
    if (params.beginTime) {
      newSearchParams.beginTime = dayjs(params.beginTime).format('YYYY-MM-DD');
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 上传成功的方法
  const uploadSuccess = (resData: any) => {
    if (typeof resData === 'boolean') {
      message.success('导入成功');
      getData();
    }
  }

  // 删除单条
  const deleteItem = async (id: number) => {
    const res = await DeleteProductInsureRecord(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns: any = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: 'adasSN',
      dataIndex: 'adasSn',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: 'aebSN',
      dataIndex: 'aebSn',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '相机SN',
      dataIndex: 'carmaSn',
      width: 130,
      ellipsis: { showTitle: false },
    },
    {
      title: '投保时间',
      dataIndex: 'beginTime',
      width: 160,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD') : value,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 240,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType='/car-admin/product-insure-record/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除该数据吗?"
              onConfirm={() => deleteItem(item.id)}
            >
              <PermissionButton operateType='/car-admin/product-insure-record/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="aebSN" name="aebSn">
              <Input placeholder="请输入aebSN" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="adasSN" name="adasSn">
              <Input placeholder="请输入adasSN" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="相机SN" name="carmaSn">
              <Input placeholder="请输入相机SN" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder="请输入车架号" allowClear  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">搜索</Button>
              <span style={{ margin: '0 5px 0 0' }}></span>
              <PermissionButton
                operateType='/car-admin/product-insure-record/add'
                type="primary"
                onClick={() => setOperateInfo({ type: 'add' })}
              >
                添加
              </PermissionButton>
              <span style={{ margin: '0 5px 0 5px' }}></span>
              <ExportButton
                url={productInsureRecordListRequestPath}
                fileName='设备投保记录'
                getParamsFunc={getExportParams}
                requestMethod="GET"
                permissionKey='/car-admin/product-insure-record/export'
              />
              <Button style={{ margin: '0 8px 0 8px' }} onClick={() => {downloadTempele('excel/product_insure_record.xlsx')}}>下载导入模板</Button>
              <UploadExcel
                uploadRequestPath={importProductInsureRecord}
                uploadPermissionKey="/car-admin/product-insure-record/import"
                uploadButtonProps={{ children: '批量导入' }}
                uploadSuccessFunc={() => {
                  message.success('导入成功');
                  getData();
                }}
              />
              <span style={{ margin: '0 5px 0 0' }}></span>
              <Button type="primary" onClick={() => setOperateInfo({ type: 'productInsure' })}>最新需投保产品</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {/* 操作最新需投保产品的弹窗 */}
      {operateInfo.type === 'productInsure' && <ProductInsureModal  closeModal={() => setOperateInfo({})}  reloadData={() => getData() }
      />}
    </Card>
  )
}

export default productInsureRecord;