// extracted by mini-css-extract-plugin
export default {"carDiagnosis":"carSitution_carDiagnosis__T3v29","searchBox":"carSitution_searchBox__LAuq6","carInfoBox":"carSitution_carInfoBox__8yBXV","carInfoTitle":"carSitution_carInfoTitle__8UoCe","carInfoContent":"carSitution_carInfoContent__vWgFG","carInfoPerson":"carSitution_carInfoPerson__1ZZ97","carInfoDriver":"carSitution_carInfoDriver__5zJXR","personPhone":"carSitution_personPhone__zR1Da","driverPhone":"carSitution_driverPhone__Xnxsl","carMap":"carSitution_carMap__BTAwG"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnosis\":\"carSitution_carDiagnosis__T3v29\",\"searchBox\":\"carSitution_searchBox__LAuq6\",\"carInfoBox\":\"carSitution_carInfoBox__8yBXV\",\"carInfoTitle\":\"carSitution_carInfoTitle__8UoCe\",\"carInfoContent\":\"carSitution_carInfoContent__vWgFG\",\"carInfoPerson\":\"carSitution_carInfoPerson__1ZZ97\",\"carInfoDriver\":\"carSitution_carInfoDriver__5zJXR\",\"personPhone\":\"carSitution_personPhone__zR1Da\",\"driverPhone\":\"carSitution_driverPhone__Xnxsl\",\"carMap\":\"carSitution_carMap__BTAwG\"}";
        // 1743672215208
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  