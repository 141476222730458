/**
 * 操作承保记录(添加或编辑)
 */
import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Row, Col, Select} from 'antd';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

interface OperateModalProps {
  closeModal: () => void;
  data?: any;
  carTypeList: any[]
}

function numberToChinese(num: number) {
  if (typeof num !== 'number' || num < 0 || !Number.isInteger(num)) {
      throw new Error('输入必须是正整数');
  }
  if (num === 0) {
      return '零元整';
  }
  let numStr = num.toString();
  const length = numStr.length;
  // 补足到4的倍数长度
  const mod = length % 4;
  if (mod !== 0) {
      numStr = '0'.repeat(4 - mod) + numStr;
  }
  const sections = [];
  for (let i = 0; i < numStr.length; i += 4) {
      const section = numStr.substr(i, 4);
      sections.push(section);
  }
  let chineseStr = '';
  for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const cn = convertFourDigits(section);
      if (cn === '') {
          continue;
      }
      const level = sections.length - 1 - i;
      let unit = '';
      if (level === 0) {
          unit = '';
      } else if (level % 2 === 1) {
          unit = '万';
      } else {
          unit = '亿';
      }
      // 处理跨分组的零：当前分组的千位是零，且不是第一个分组
      if (i > 0) {
          const currentQianWei = parseInt(section[0], 10);
          if (currentQianWei === 0) {
              // 前一个字符是否是单位（万/亿）
              if (chineseStr.length > 0 && (chineseStr.endsWith('万') || chineseStr.endsWith('亿'))) {
                  chineseStr += '零';
              }
          }
      }
      chineseStr += cn + unit;
  }
  // 处理连续的零
  chineseStr = chineseStr.replace(/零+/g, '零');
  // 去除末尾的零
  if (chineseStr.endsWith('零')) {
      chineseStr = chineseStr.slice(0, -1);
  }
  return chineseStr + '元整';
}

function convertFourDigits(section: string) {
  const digits = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const units = ['仟', '佰', '拾', ''];
  let result = '';
  let hasZero = false;
  let started = false;
  for (let i = 0; i < 4; i++) {
      const num = parseInt(section[i], 10);
      const unit = units[i];
      if (num !== 0) {
          started = true;
          if (hasZero) {
              result += '零';
              hasZero = false;
          }
          result += digits[num] + unit;
      } else {
          if (started) {
              // 检查后面是否有非零数字
              let hasNonZeroAfter = false;
              for (let j = i + 1; j < 4; j++) {
                  if (parseInt(section[j], 10) !== 0) {
                      hasNonZeroAfter = true;
                      break;
                  }
              }
              if (hasNonZeroAfter) {
                  hasZero = true;
              }
          }
      }
  }
  return result;
}


function isBeijingPlate(plateNumber:string) {
  const regex = /^[京]/;
  return regex.test(plateNumber);
} 

const jingZiXieStr = (plateNumber: string, carType: string) => {
  if (isBeijingPlate(plateNumber) && carType.includes('自卸')) {
    return '举升过程中造成的标的车辆损失及第三方损失适用绝对免赔率25%或绝对免赔额5000元，绝对免赔取高者，并指定修理厂施救、维修。'
  } else {
    return ''
  }
}


const PreviewContract = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { closeModal, data, carTypeList = []} = props;

  const downLoadFile = async (originData: any) => {

    const loadTemplate = async (url: string) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return arrayBuffer;
    }
    
    try {
      // 1. 加载模板
      const template = await loadTemplate(`${window.location.origin}/server_apply_1.docx`);
      const template_service = await loadTemplate(`${window.location.origin}/service_demo_2.docx`);
      const template_contract = await loadTemplate(originData.installmentType === '分期' ? `${window.location.origin}/installment_contract_1.docx` : `${window.location.origin}/contract_2.docx`)
      // 2. 初始化文档处理器（使用 PizZip 替代 JSZip）
      const zip = new PizZip(template);
      const service_zip = new PizZip(template_service)
      const contract_zip = new PizZip(template_contract)
      // const imageOptions = { centered: false }; // 图片是否居中
      const doc = new Docxtemplater().loadZip(zip);
      const service_doc = new Docxtemplater().loadZip(service_zip)
      const contract_doc = new Docxtemplater().loadZip(contract_zip)

      // 3. 准备数据（含图片）
      const data = {
       date: dayjs().format(`YYYY 年 MM 月 DD 日`),
       ...originData,
       commercialFeeChinese: numberToChinese(Number(originData.commercialFee)),
       jingZiXieStr: jingZiXieStr(originData.plateNumber, originData.carType),
       sandianStr: originData.threeElectricityFlag ? '单独三电损失免赔、车辆自燃损失免赔。' : '',
       dontIncludesSandianStr: originData.fuelType === '新能源/电' && !originData.threeElectricityFlag ? '三电损失免赔。' : '',
       ziranStr: originData.selfBurningFlag ? '风险减量服务参与方或其允许的驾驶人在使用参加风险减量服务车辆过程中，“由于本车电器、线路、供油系统、供气系统等参加风险减量服务车辆自身原因或所载货物自身原因起火燃烧”，按服务合同“特别约定”负责补偿；风险减量服务参与方需配合提供公安消防部门出具的火灾原因证明。' : '',
       upStr: originData.liftFlag? '举升过程中造成的标的车辆损失及第三方损失适用绝对免赔率25%或绝对免赔额5000元，绝对免赔取高者，并指定修理厂施救、维修。' : '',
       monthlyPayment: Math.floor(originData.commercialFee / (Number(originData.installmentCount || 0) + 2)),
       partnerContract: originData.applicatType === '营业执照' ? originData.applicant : '',
       legalPPerson: originData.applicatType === '营业执照' ? originData. legalPPerson : '/',
       carTypeName: originData.carType,
       startTime: dayjs().format('YYYY 年 MM 月'),
       endTime: dayjs().add(1,'year').format('YYYY 年 MM 月'),
       contractSn: originData.contractSn.length > 12 ? originData.contractSn.slice(0, originData.contractSn.lastIndexOf('-')) : originData.contractSn
      };
      if (!data.jingZiXieStr && !data.sandianStr && !data.ziranStr && !data.dontIncludesSandianStr && !data.upStr) {
        data.noneStr = '无'
      } else {
        data.noneStr = ''
      }
      console.log(data, '123123')

      // 4. 替换模板内容
      doc.setData(data);
      service_doc.setData(data)
      contract_doc.setData(data)

      // 5. 渲染文档（处理错误）
      try {
        doc.render();
        service_doc.render()
        contract_doc.render()
      } catch (error) {
        console.error("模板渲染错误:", error);
        throw error;
      }

      // 6. 生成并保存文件
      const out = doc.getZip().generate({ type: 'blob' });
      const service_out = service_doc.getZip().generate({ type: 'blob' });
      const contract_out = contract_doc.getZip().generate({type: 'blob'})
      saveAs(out, `${originData.plateNumber}_智驾服务方案申请单.docx`);
      setTimeout( () => {
        saveAs(service_out, `${originData.plateNumber}_智驾服务方案服务单（电子单）.docx`)
      }, 500)
      setTimeout(() => {
        saveAs(contract_out, `${originData.plateNumber}_智能辅助驾驶服务解决方案服务合同.docx`)
      }, 1000)

      closeModal()
    } catch (error) {
      console.error("生成失败:", error);
    }
  };

// 提交
  const submit = async (values: any) => {
    const dataCopy = {...values, ...data}
    if (dataCopy.applicant.length > 5) {
      dataCopy.applicatType = '营业执照'
    }
    if (dataCopy.applicant.length <= 5) {
      dataCopy.applicatType = '身份证'
    }
    if (dataCopy.applicatAddress ) {
      dataCopy.applicatAddressShort = dataCopy.applicatAddress.slice(0, 6)
    }
    console.log(dataCopy, '1111')
    downLoadFile(dataCopy)
  }

  return (
    <Modal
      title={'预览合同'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{maxHeight: '70vh', overflow: 'auto'}}
      centered
      maskClosable={false}
      width={1000}
    >
      <Form form={form} autoComplete="off" layout='vertical' onFinish={submit} initialValues={{}}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="投保人地址" name="applicatAddress">
              <Input placeholder='详细地址'></Input>
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item label="投保人地址到市/ 县" name="applicatAddressShort">
              <Input placeholder="市/ 县地址" allowClear/>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="投保人联系电话" name="applicatPhone">
              <Input placeholder='联系电话'></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="法人" name="legalPPerson" >
              <Input placeholder="请输入法人" allowClear />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item label="投保人性质" name="applicatType" rules={[{required: true}]}>
              <Select placeholder="请选择" options={[{label: '营业执照', value: '营业执照'}, {label: '身份证', value: '身份证'}]}></Select>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="投保人证件号" name="applicatCardId">
              <Input placeholder="请输入投保人证件号" allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default PreviewContract;