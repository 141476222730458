/**
 * 操作部标机设备(添加、编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Checkbox, Radio, Select } from 'antd';
import { jt808Version } from '@/constants/jtDevice';
import {
  upsertJtDeviceRequest,
  jtDeviceDetailRequest,
  jtDeviceListRequest
} from '@/service/jtDevice';
import { useJtDeviceChannel } from '@/utils/jtDevice';

interface OperateModalProps {
  type: string; // 操作类型
  editId?: number; // 编辑的部标机设备ID
  onClose: () => void; // 关闭弹窗的方法
  reloadData: (params:any) => void; // 操作成功后调用的方法
  carManageUse?: boolean; // 是否在车辆管理中使用
}
const OperateModal = (props: OperateModalProps) => {
  const { type, editId, onClose, reloadData, carManageUse } = props;
  const [loading, setLoading] = useState<boolean>(false); // 保存接口是否请求中
  const [alreadyHasDevice, setAlreadyHasDevice] = useState<boolean>(false); // 添加部标机时校验是否已经有对应终端手机号的部标机设备
  const [form] = Form.useForm();
  const jtDeviceChannelData = useJtDeviceChannel();

  // 编辑时表单赋值
  const setEditValues = async () => {
    const res = await jtDeviceDetailRequest(editId as number);
    form.setFieldsValue(res.data?.data || {});
  };

  useEffect(() => {
    if (type === 'edit') {
      setEditValues();
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (alreadyHasDevice) {
      // 车辆管理添加部标机如果已经匹配到设备直接回填终端手机号
      onClose();
      reloadData(values);
      return;
    }
    try {
      setLoading(true);
      const params = { ...values };
      // 2019版本补零到20长度，其他低版本补零到12长度
      const slicePhone = values.phone.slice(
        params.version === '2019' ? -20 : -12
      );
      params.phone = slicePhone.padStart(
        params.version === '2019' ? 20 : 12,
        '0'
      );
      if (type === 'edit') {
        params.id = editId;
      }
      const res = await upsertJtDeviceRequest(params);
      setLoading(false);
      if (res.data?.data) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        onClose();
        reloadData(params);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // 终端手机号失去焦点
  const onPhoneBlur = async (e: any) => {
    const phone = e.target.value;
    // 车辆管理使用添加部标机设备时校验是否已经有此终端手机号
    if (carManageUse && type === 'add' && phone) {
      const res = await jtDeviceListRequest({
        page: 1,
        limit: 1,
        phone
      });
      if (res.data?.data?.length) {
        form.setFieldsValue(res.data?.data[0]);
        setAlreadyHasDevice(true);
      } else {
        setAlreadyHasDevice(false);
        form.setFieldsValue({
          terminalId: '',
          sim: '',
          version: '',
          channelNoList: []
        });
      }
    }
  };

  const manufacturerData = [
    {
      label: '博实结',
      value: 1
    },
    {
      label: '华宝',
      value: 2
    },
    {
      label: '易联星拓',
      value: 3
    },
    {
      label: '珠海骏驰',
      value: 4
    },
    {
      label: '三一海星',
      value: 5
    },
    {
      label: '鼎奕科技',
      value: 6
    },
    {
      label: '领行',
      value: 7
    },
    {
      label: '通立',
      value: 8
    },
    {
      label: '极目智能',
      value: 9
    }
  ];

  return (
    <Modal
      title={type === 'add' ? '添加部标机设备' : '编辑部标机设备'}
      open
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="终端手机号"
          name="phone"
          rules={[{ required: true, message: '请填写终端手机号' }]}
          extra={
            alreadyHasDevice
              ? '该终端手机号对应设备已存在，可直接点击确定按钮关联车辆'
              : ''
          }
        >
          <Input
            placeholder="请填写终端手机号"
            disabled={type === 'edit'}
            onBlur={onPhoneBlur}
          />
        </Form.Item>
        <Form.Item
          label="终端ID"
          name="terminalId"
          rules={[{ required: true, message: '请填写终端ID' }]}
        >
          <Input
            placeholder="请填写终端ID"
            disabled={type === 'edit' || alreadyHasDevice}
          />
        </Form.Item>
        <Form.Item label="SIM卡ICCID" name="sim">
          <Input placeholder="请填写SIM卡ICCID" disabled={alreadyHasDevice} />
        </Form.Item>
        <Form.Item
          label="808协议版本"
          name="version"
          rules={[{ required: true, message: '请选择808协议版本' }]}
        >
          <Radio.Group options={jt808Version} disabled={alreadyHasDevice} />
        </Form.Item>
        <Form.Item
          label="视频通道"
          name="channelNoList"
          rules={[{ required: true, message: '请选择视频通道' }]}
        >
          <Checkbox.Group
            options={jtDeviceChannelData}
            disabled={alreadyHasDevice}
          />
        </Form.Item>
        <Form.Item label="厂家" name="manufacturer">
          <Select placeholder="请选择硬件配置类型" options={manufacturerData} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
