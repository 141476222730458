// extracted by mini-css-extract-plugin
export default {"fixPageContentBox":"channelAssessment_fixPageContentBox__8Sfcl","topTableItem":"channelAssessment_topTableItem__+KQu-","topTableItemMain":"channelAssessment_topTableItemMain__UzGN4","topTableItemMainThree":"channelAssessment_topTableItemMainThree__UU1fX","topTableItemMainSix":"channelAssessment_topTableItemMainSix__3y5ge","topTableItemMainC":"channelAssessment_topTableItemMainC__zFxlA","topTableItemMainP":"channelAssessment_topTableItemMainP__ybHWm","topTableItemMainR":"channelAssessment_topTableItemMainR__uNlS+"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"fixPageContentBox\":\"channelAssessment_fixPageContentBox__8Sfcl\",\"topTableItem\":\"channelAssessment_topTableItem__+KQu-\",\"topTableItemMain\":\"channelAssessment_topTableItemMain__UzGN4\",\"topTableItemMainThree\":\"channelAssessment_topTableItemMainThree__UU1fX\",\"topTableItemMainSix\":\"channelAssessment_topTableItemMainSix__3y5ge\",\"topTableItemMainC\":\"channelAssessment_topTableItemMainC__zFxlA\",\"topTableItemMainP\":\"channelAssessment_topTableItemMainP__ybHWm\",\"topTableItemMainR\":\"channelAssessment_topTableItemMainR__uNlS+\"}";
        // 1743672213425
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  